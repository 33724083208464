@use "../variables" as variable;

.banner {
    $box : &;
    position: relative;
    min-height: 38rem;
    display: flex;
    align-items: flex-end;
    overflow: hidden;

    // &::after {
    //     content: "";
    //     @include variable.cover();
    //     object-fit: cover;
    //     background-color: variable.$primary;
    //     opacity: .25;
    // }

    &__cover {
        @include variable.cover();
        object-fit: cover;
        background-color: variable.$primary;
        opacity: .25;
    }

    &__img {
        @include variable.cover();
        object-fit: cover;
    }

    &__content {
        padding: 5rem 2rem;
        position: relative;
        z-index: 2;
        color: #fff;
    }

    &__title {
        font-size: 3.2rem;
        line-height: 1.25em;
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 1rem;

        b, strong {
            text-decoration: underline;
            text-decoration-color: variable.$secondary;
            text-underline-offset: 0.2em;
        }
    }

    &__text {
        margin-bottom: 2rem;
    }
}


@include variable.media(1260px, min) {
    .banner {
        $box : &;
        min-height: 60rem;
        align-items: center;

        &__content {
            padding: 10rem 12rem;
        }

        &__title {
            font-size: 7.2rem;
        }

        &.right {
            #{$box} {
                &__content {
                    margin-left: 33.33%;
                }
            }
        }
    }
}