@use "../../variables" as variable;

.products-nav {
    width: 100%;
    background-color: variable.$light;
    position: relative;

    &__header {
        display: none;
    }

    &__current {
        font-size: 1.6rem;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 2rem;
        min-height: 5.6rem;
        background-color: variable.$secondary;
        color: #fff;
    }
}

.products-menu {
    font-size: 1.6rem;
    font-weight: 400;
    background-color: variable.$light;

    &__item {
        margin-bottom: .4rem;
    }

    &__link {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem 2rem;
        min-height: 5.6rem;

        img {
            opacity: 0;
        }

        &:hover, &.active {
            background-color: variable.$secondary;
            color: #fff;

            img {
                opacity: 1;
            }
        }
    }
}


@include variable.media(1259.99px, max) {	
    .products-nav {
        $nav : &;

        &__menu {
            position: absolute;
            left: 0;
            width: 100%;
            top: 100%;
            z-index: 2;
            display: none;
        }


        &:hover {
            #{$nav} {
                &__menu {
                    display: block;
                }
            }
        }
    }

    .products-menu {
        &__link {
            &.active {
                display: none;
            }
        }
    }
}


@include variable.media(1260px, min) {	
    .products-nav {
        padding-bottom: 2rem;

        &__header {
            display: flex;
            align-items: center;
            padding: 4rem 4.6rem 2rem;
            font-size: 2.4rem;
            font-weight: 600;
            text-transform: uppercase;

            img {
                margin-right: 2rem;
            }
        }

        &__current {
            display: none;
        }
    }


    .products-menu { 
        &__link {
            padding: 1rem 4.6rem;
        }
    }
}