@use "../../variables" as variable;

.tc-data {
    padding: 0 2rem;

    .btn-contact {
        margin-bottom: 2rem;
    }
}

@include variable.media(1260px, min) {
    .tc-data {
        padding: 0;

        .btn-contact {
            margin-bottom: 0;
        }

        &__row {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
}