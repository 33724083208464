@use "../../variables" as variable;

.s-apply {
    padding-top: 2rem;

    &__header {
        padding: 0 2rem;
        margin-bottom: 3rem;
    }

    &__title {
        font-size: 2rem;
        font-weight: 600;
        line-height: 1.25em;
        text-transform: uppercase;
    }

    &__name {
        font-size: 2.4rem;
        line-height: 1.25em;
        font-weight: 600;
        color: variable.$secondary;
    }

    &__row {
        padding: 0 2rem;
    }

    &__form {
        margin-bottom: 4rem;
    }
}




.form-apply-submit {
    display: flex;
    justify-content: center;
}


.apply-more-header {
    padding: 0 2rem;
    margin-bottom: 3rem;
}


@include variable.media(768px, min) {

    .s-apply {
        &__title {
            font-size: 6.4rem;
        }

        &__name {
            font-size: 6.4rem;
        }
    }

    .form-apply {
        display: flex;
        flex-wrap: wrap;
    
        .form-field {
            width: 50%;
    
            &--full {
                width: 100%;
            }
        }
    }

    .form-apply-submit {
        justify-content: flex-end;
    }
}


@include variable.media(1260px, min) {
    .s-apply {
        padding-top: 4rem;
    
        &__header {
            padding: 0;
            margin-bottom: 6rem;
        }

        &__row {
            display: flex;
            padding: 0;
        }
    
        &__form {
            width: 60%;
            order: 2;
            padding-left: 8rem;
            margin-bottom: 0;
        }
    
        &__info {
            width: 40%;
        }
    }

    .apply-more-header {
        padding: 0;
        margin-bottom: 4rem;
    }
}