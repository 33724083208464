@use "../variables" as variable;

.offer-banner {
    height: 45rem;
    position: relative;
    color: #fff;
    overflow: hidden;

    &__img {
        @include variable.cover();
        object-fit: cover;
    }

    &__content {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        display: flex;

    }

    &__row {
        display: flex;
        align-items: flex-end;
        justify-content: space-evenly;
    }

    &__desc {
        position: relative;
    }

    &__header {
        margin-bottom: 2rem;
    }

    &__text {
        font-size: 1.8rem;
        line-height: 1.25em;
        font-weight: 300;
    }

    &__info {
        position: relative;
    }

    &__dec-info {
        position: absolute;
        bottom: 100%;
        left: 100%;
        transform: translate(-4rem, 2rem);
    }
}


@include variable.media(991.99px, max) {
    .offer-banner {
        &__row {
            flex-direction: column;
            width: max-content;
            padding: 0 2rem;
            align-items: flex-start;
        }

        &__dec_1,  &__dec_2 {
            display: none;
        }

        &__dec-info {
            transform: translate(3rem, 7rem);
            width: 4rem;
        }
    }
}


@include variable.media(992px, min) {
    .offer-banner {
        &__content {
            width: 50%;
            display: flex;
            align-items: center;
            padding-top: 5rem;
        }

        &__row {
            width: 100%;
            align-items: flex-end;
        }

        &__text {
            font-size: 2.4rem;
        }

        &__dec_1 {
            position: absolute;
            left: -6rem;
            top: 0;
        }
        
        &__dec_2 {
            position: absolute;
            right: -1rem;
            top: calc(100% - 2rem);
            opacity: .3;
        }
    }
}