@import url("https://use.typekit.net/lcl4xfp.css");
*,
::after,
::before {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  box-sizing: border-box;
}

img,
article,
main,
aside,
details,
figcaption,
figure,
footer,
header,
nav {
  display: block;
}

address {
  font-style: inherit;
}

img {
  max-width: 100%;
}

ol,
ul {
  list-style: none;
}

li:empty,
p:empty {
  display: none;
}

textarea,
select,
input,
button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: inherit;
  background: transparent;
}

strong {
  font-weight: bold;
}

a {
  text-decoration: none;
  color: inherit;
}

:focus,
:active {
  outline: none;
}

/*
* Sets element media query
*/
/*
* Fills relative parent width and height
*/
/*
* Centers element by type
*/
html {
  font-size: 62.5%;
}

body {
  font-family: "tt-commons-pro", sans-serif;
  color: #2C3934;
  font-size: 1.6rem;
  background-color: #ffffff;
}

main {
  overflow: hidden;
  padding-top: 9.6rem;
}
@media screen and (min-width: 1260px) {
  main {
    padding-top: 16rem;
  }
}

.page-template-template-homepage main {
  padding-top: 0;
}

.center {
  text-align: center;
}

.relative {
  position: relative;
}

a,
button {
  cursor: pointer;
}

.title {
  line-height: 1.25em;
}
.title--1 {
  font-weight: 600;
  font-size: 3.6rem;
}
@media screen and (min-width: 768px) {
  .title--1 {
    font-size: 4.8rem;
  }
}
@media screen and (min-width: 1260px) {
  .title--1 {
    font-size: 6.4rem;
  }
}
.title--2 {
  font-weight: 600;
  font-size: 3.6rem;
}
@media screen and (min-width: 768px) {
  .title--2 {
    font-size: 4.8rem;
  }
}
.title--3 {
  font-weight: 400;
  font-size: 2.4rem;
}
@media screen and (min-width: 768px) {
  .title--3 {
    font-size: 3.6rem;
  }
}
.title--4 {
  font-weight: 400;
  font-size: 2.4rem;
}
.title--secondary {
  color: #48A681;
}
.title--upper {
  text-transform: uppercase;
}
.title--regular {
  font-weight: 400;
}
.title b, .title strong {
  text-decoration: underline;
  text-decoration-color: #48A681;
  text-underline-offset: 0.2em;
}
.title--white {
  color: #ffffff;
}
.title--white b, .title--white strong {
  text-decoration-color: #ffffff;
}

.section-margin {
  margin-bottom: 5rem;
}
@media screen and (min-width: 576px) {
  .section-margin {
    margin-bottom: 8rem;
  }
}
@media screen and (min-width: 1260px) {
  .section-margin {
    margin-bottom: 10rem;
  }
}

.section-header {
  padding: 2rem 2rem 3rem;
}
.section-header__row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.section-header__info {
  font-style: italic;
  color: #EEEEEE;
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 1.25em;
  text-transform: uppercase;
}
@media screen and (min-width: 992px) {
  .section-header__info {
    font-size: 3em;
  }
}
@media screen and (min-width: 1560px) {
  .section-header__info {
    font-size: 6.4rem;
  }
}
@media screen and (min-width: 768px) {
  .section-header {
    padding: 4rem 2rem 4rem;
  }
}
@media screen and (min-width: 1260px) {
  .section-header {
    padding: 8rem 0 6rem;
  }
}

.text {
  font-weight: 300;
  font-size: 1.6rem;
  line-height: 1.75em;
  text-transform: none;
  text-decoration: none;
}
.text--regular {
  font-weight: 400;
}
.text--large {
  font-size: 1.8rem;
}
@media screen and (min-width: 768px) {
  .text--large {
    font-size: 2.2rem;
  }
}
.text b, .text strong {
  font-weight: 600;
}
.text h1, .text h2, .text h3, .text h4, .text h5, .text h6 {
  font-weight: 600;
  line-height: 1.25em;
  margin-bottom: 3rem;
}
.text h1 {
  font-size: 3rem;
}
.text h2 {
  font-size: 2.4rem;
  font-weight: 600;
}
.text h3 {
  font-size: 2.2rem;
  font-weight: 400;
}
.text h4 {
  font-size: 1.5rem;
}
.text h5 {
  font-size: 1.25rem;
}
.text h6 {
  font-size: 1rem;
}
.text p {
  margin-bottom: 20px;
}
.text p:last-child {
  margin-bottom: 0;
}
.text ol {
  list-style: none;
  counter-reset: item;
}
.text ol > li {
  padding-left: 3rem;
  margin-top: 1rem;
  position: relative;
}
@media screen and (min-width: 992px) {
  .text ol > li {
    padding-left: 4rem;
    margin-top: 3rem;
  }
}
.text ol > li::before {
  counter-increment: item;
  content: counters(item, ".", decimal-leading-zero) ".";
  position: absolute;
  top: 0;
  left: 0;
  font-size: 1.6rem;
  font-weight: 400;
}
.text ul {
  list-style: none;
}
.text ul li {
  position: relative;
  padding-left: 3rem;
  margin-top: 2rem;
}
@media screen and (min-width: 992px) {
  .text ul li {
    padding-left: 4rem;
    margin-top: 3rem;
  }
}
.text ul li::before {
  content: "";
  position: absolute;
  top: 0.5rem;
  left: 0;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  background-color: #48A681;
}
@media screen and (min-width: 992px) {
  .text ul li::before {
    width: 1.6rem;
    height: 1.6rem;
  }
}
.text img {
  height: auto;
}
.text a {
  font-weight: 600;
}
.text a:hover {
  text-decoration: underline;
}
.text hr {
  display: block;
  width: 100%;
  border-bottom: 1px solid #d0dfc0;
  margin-bottom: 40px;
  margin-top: 40px;
}
.text iframe {
  display: block;
  max-width: 100%;
  margin: 0 auto;
  margin-bottom: 40px;
}

.desc-cols {
  padding: 0 2rem;
}
.desc-cols__item {
  margin-bottom: 4rem;
}

@media screen and (min-width: 992px) {
  .desc-cols {
    display: flex;
    justify-content: space-between;
    margin: 0 -2rem;
    padding: 0;
  }
}
@media screen and (min-width: 992px) and (min-width: 1260px) {
  .desc-cols {
    margin: 0 -4rem;
  }
}
@media screen and (min-width: 992px) {
  .desc-cols__item {
    width: 50%;
    padding: 0 4rem;
    max-width: 80rem;
  }
}
.grid {
  width: 100%;
  max-width: 1800px;
  margin: 0 auto;
}
@media screen and (min-width: 1260px) {
  .grid {
    padding: 0 4rem;
  }
}
@media screen and (min-width: 1560px) {
  .grid {
    padding: 0 8rem;
  }
}

.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 2.4rem;
  min-height: 5.2rem;
  background-color: #48A681;
  color: #fff;
  font-size: 1.6rem;
  font-weight: 300;
  min-width: 15rem;
  cursor: pointer;
  transition: all 0.3s;
}
.btn span {
  margin-right: 1.4rem;
}
.btn .wpcf7-spinner {
  display: none;
}
.btn img, .btn svg {
  transition: all 0.3s;
}
.btn:hover {
  filter: drop-shadow(0px 15px 25px rgba(72, 166, 129, 0.4));
}
.btn:hover img, .btn:hover svg {
  transform: rotate(45deg);
}

.btn-link {
  display: inline-flex;
  align-items: baseline;
  font-size: 1.6rem;
  font-weight: 500;
  text-transform: uppercase;
}
.btn-link:hover {
  text-decoration: underline;
  color: #48A681;
}
.btn-link img {
  margin-right: 1.6rem;
}

.btn-apply {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 4rem 2rem 3rem;
  background-color: #48A681;
  color: #fff;
  font-size: 2.8rem;
  font-weight: 600;
  text-transform: uppercase;
}
.btn-apply img {
  max-width: 5rem;
  max-height: 5rem;
  width: auto;
  height: auto;
  margin-right: 2rem;
}
.btn-apply:hover {
  filter: drop-shadow(0px 15px 25px rgba(72, 166, 129, 0.4));
}
@media screen and (max-width: 575px) {
  .btn-apply {
    width: 100%;
  }
}

.btn-expand {
  width: 4rem;
  min-width: 4rem;
  height: 4rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #48A681;
  border-radius: 50%;
  transition: all 0.3s;
}
@media screen and (min-width: 1260px) {
  .btn-expand {
    width: 6rem;
    min-width: 6rem;
    height: 6rem;
  }
}

::-webkit-input-placeholder { /* Edge */
  color: #2C3934;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #2C3934;
}

::placeholder {
  color: #2C3934;
}

input[type=text], input[type=email], input[type=tel], input[type=password], textarea {
  font-family: inherit;
  font-size: 1.6rem;
  line-height: 1.5em;
  padding: 1.4rem 2rem;
  border: 2px solid #D5D5D5;
  width: 100%;
  font-weight: 300;
  color: #2C3934;
}
@media screen and (min-width: 768px) {
  input[type=text], input[type=email], input[type=tel], input[type=password], textarea {
    padding: 2rem 2.4rem;
  }
}
input[type=text].disabled, input[type=email].disabled, input[type=tel].disabled, input[type=password].disabled, textarea.disabled {
  opacity: 0.5;
  pointer-events: none;
}
input[type=text].wpcf7-not-valid, input[type=email].wpcf7-not-valid, input[type=tel].wpcf7-not-valid, input[type=password].wpcf7-not-valid, textarea.wpcf7-not-valid {
  border-color: #9e000f;
}
input[type=text]:focus, input[type=email]:focus, input[type=tel]:focus, input[type=password]:focus, textarea:focus {
  border-color: #48A681;
}

.wpcf7-form-control-wrap[data-name=place] {
  position: relative;
}
.wpcf7-form-control-wrap[data-name=place]::after {
  content: "";
  position: absolute;
  top: 2.7rem;
  right: 3rem;
  width: 8px;
  height: 20px;
  background-image: url(../../assets/dropdown.svg);
  background-repeat: no-repeat;
  background-position: center;
}

select {
  font-family: inherit;
  font-size: 1.6rem;
  line-height: 1.5em;
  padding: 2rem 2.4rem;
  border: 2px solid #D5D5D5;
  width: 100%;
  font-weight: 300;
  color: #2C3934;
  position: relative;
  cursor: pointer;
}
select:focus {
  border-color: #48A681;
}

input[type=radio] {
  min-width: 2.2rem;
  width: 2.2rem;
  height: 2.2rem;
  border: 1px solid #2C3934;
  border-radius: 50%;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}
input[type=radio]:checked {
  background-color: #48A681;
  border-color: #48A681;
}

input[type=checkbox] {
  min-width: 20px;
  width: 20px;
  height: 20px;
  border: 2px solid #D5D5D5;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}
input[type=checkbox]::after {
  content: "";
  width: 10px;
  height: 10px;
  background-color: #48A681;
  transform: scale(0);
  transition: all 0.3s;
}
input[type=checkbox]:checked::after {
  transform: scale(1);
}

.wpcf7-list-item label {
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  font-weight: 400;
  cursor: pointer;
}
.wpcf7-list-item label a {
  font-weight: 500;
}
.wpcf7-list-item label a:hover {
  text-decoration: underline;
}
.wpcf7-list-item label input {
  margin-right: 2rem;
}

input[type=submit] {
  font-family: inherit;
  font-size: 1.4rem;
  font-weight: 300;
}
input[type=submit]:disabled {
  cursor: not-allowed;
}

.wpcf7-form-control-wrap {
  display: block;
  position: relative;
  width: 100%;
}

.wpcf7-not-valid-tip {
  position: absolute;
  top: 100%;
  left: 0;
  font-size: 1.4rem;
  padding: 0.6rem 2.4rem;
  color: #9e000f;
}

.screen-reader-response {
  display: none;
}

.wpcf7-response-output {
  background: #2C3934;
  padding: 20px;
  text-align: center;
  color: #fff;
  border-radius: 2rem 0 2rem 0;
  margin-top: 2rem;
}
@media screen and (min-width: 576px) {
  .wpcf7-response-output {
    border-radius: 3rem 0 3rem 0;
    margin-top: 3rem;
  }
}
.wpcf7-response-output:empty {
  display: none;
}

.form-acceptance {
  margin-bottom: 1.5rem;
}

.form-submit {
  display: flex;
  justify-content: center;
}

.form-field {
  margin-bottom: 20px;
}

.btn-file {
  display: flex;
  align-items: center;
  padding: 2.3rem 2.4rem;
  background-color: #48A681;
  font-size: 1.6rem;
  font-weight: 300;
  color: #fff;
  cursor: pointer;
}
.btn-file .wpcf7-form-control-wrap, .btn-file input {
  display: none;
}
.btn-file svg, .btn-file img {
  margin-right: 2rem;
}

@media screen and (min-width: 768px) {
  .form-fields {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1.6rem;
  }
  .form-field {
    width: 33.33%;
    padding: 0 1.6rem;
    margin-bottom: 3.2rem;
  }
  .form-field--full {
    width: 100%;
  }
}
.hero-slider__item {
  height: 40vw;
  max-height: 760px;
  min-height: 460px;
  position: relative;
}
.hero-slider__item .video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}
.hero-slider__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}
.hero-slider__content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  display: flex;
  align-items: center;
  text-align: center;
}
@media screen and (min-width: 1260px) {
  .hero-slider__content {
    padding: 0 2rem;
  }
}
.hero-slider__title {
  margin-bottom: 2rem;
}
.hero-slider__text {
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.4em;
  text-transform: uppercase;
  max-width: 24rem;
  margin: 0 auto 2rem;
}

@media screen and (min-width: 768px) {
  .hero-slider__text {
    font-size: 2.4rem;
    margin-bottom: 2rem;
    max-width: 100%;
  }
}
.offer-banner {
  height: 45rem;
  position: relative;
  color: #fff;
  overflow: hidden;
}
.offer-banner__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}
.offer-banner__content {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
}
.offer-banner__row {
  display: flex;
  align-items: flex-end;
  justify-content: space-evenly;
}
.offer-banner__desc {
  position: relative;
}
.offer-banner__header {
  margin-bottom: 2rem;
}
.offer-banner__text {
  font-size: 1.8rem;
  line-height: 1.25em;
  font-weight: 300;
}
.offer-banner__info {
  position: relative;
}
.offer-banner__dec-info {
  position: absolute;
  bottom: 100%;
  left: 100%;
  transform: translate(-4rem, 2rem);
}

@media screen and (max-width: 991.99px) {
  .offer-banner__row {
    flex-direction: column;
    width: max-content;
    padding: 0 2rem;
    align-items: flex-start;
  }
  .offer-banner__dec_1, .offer-banner__dec_2 {
    display: none;
  }
  .offer-banner__dec-info {
    transform: translate(3rem, 7rem);
    width: 4rem;
  }
}
@media screen and (min-width: 992px) {
  .offer-banner__content {
    width: 50%;
    display: flex;
    align-items: center;
    padding-top: 5rem;
  }
  .offer-banner__row {
    width: 100%;
    align-items: flex-end;
  }
  .offer-banner__text {
    font-size: 2.4rem;
  }
  .offer-banner__dec_1 {
    position: absolute;
    left: -6rem;
    top: 0;
  }
  .offer-banner__dec_2 {
    position: absolute;
    right: -1rem;
    top: calc(100% - 2rem);
    opacity: 0.3;
  }
}
.product-banner {
  height: 54rem;
  position: relative;
  color: #fff;
}
.product-banner::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  background-color: #2C3934;
  opacity: 0.4;
}
.product-banner__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}
.product-banner__content {
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 8rem 3rem;
}
.product-banner__title {
  margin-bottom: 2rem;
  font-size: 4.2rem;
  font-weight: 600;
  line-height: 1.25em;
  text-transform: uppercase;
}
.product-banner__title b, .product-banner__title strong {
  text-decoration: underline;
  text-decoration-color: #48A681;
  text-underline-offset: 0.2em;
}
.product-banner__text {
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.5em;
}

@media screen and (min-width: 1260px) {
  .product-banner {
    height: 60rem;
  }
  .product-banner__title {
    font-size: 4.8rem;
  }
}
.offer-list {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
}
.offer-list__item {
  width: 50%;
  display: flex;
}
@media screen and (min-width: 992px) {
  .offer-list__item {
    width: 25%;
  }
}

.offer-box {
  display: block;
  width: 100%;
  padding: 3rem 2rem;
  background-color: #F6FBF9;
  position: relative;
}
.offer-box:hover {
  filter: drop-shadow(0px 15px 25px rgba(72, 166, 129, 0.4));
  z-index: 2;
}
.offer-box__image {
  position: relative;
  width: 100%;
  padding-bottom: 80%;
  margin-bottom: 2rem;
}
.offer-box__image img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 100%;
  mix-blend-mode: darken;
}
.offer-box__content {
  position: relative;
}
.offer-box__title {
  font-size: 1.8rem;
  line-height: 1.25em;
  font-weight: 400;
  margin-bottom: 0.6rem;
}
.offer-box__text {
  font-size: 1.2rem;
  font-weight: 300;
  line-height: 1.5em;
}
.offer-box.cover .offer-box__image {
  position: static;
}
.offer-box.cover .offer-box__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.offer-box.cover .offer-box__content {
  color: #fff;
}

@media screen and (max-width: 991.99px) {
  .offer-list__item:first-child .offer-box {
    background-color: #fff;
  }
}
@media screen and (min-width: 1260px) {
  .offer-box {
    padding: 4rem 3rem;
  }
  .offer-box__image {
    margin-bottom: 3rem;
  }
  .offer-box__title {
    font-size: 2.2rem;
    margin-bottom: 0.6rem;
  }
  .offer-box__text {
    font-size: 1.6rem;
  }
}
.about-char {
  position: relative;
}
.about-char svg {
  max-width: 100%;
  height: auto;
}

.about-char-box__icon {
  margin-bottom: 2rem;
}
.about-char-box__icon img {
  max-height: 8rem;
  mix-blend-mode: multiply;
}
.about-char-box__info {
  margin-bottom: 1.2rem;
}
.about-char-box__text {
  font-size: 1.2rem;
  line-height: 1.25em;
  font-weight: 300;
}
@media screen and (min-width: 1260px) {
  .about-char-box__text {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 991.99px) {
  .about-char svg {
    display: none;
  }
  .about-char__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1.5rem;
  }
  .about-char__item {
    width: 50%;
    margin-bottom: 3rem;
    padding: 0 1.5rem;
  }
  .about-char-box__icon {
    height: 8rem;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
}
@media screen and (min-width: 992px) {
  .about-char__item {
    width: 30%;
    position: absolute;
    display: flex;
  }
  .about-char__item:nth-child(1) {
    bottom: 72%;
    left: 0;
  }
  .about-char__item:nth-child(2) {
    bottom: 72%;
    right: 0;
    justify-content: flex-end;
  }
  .about-char__item:nth-child(3) {
    bottom: 20%;
    left: 6%;
  }
  .about-char__item:nth-child(4) {
    bottom: 20%;
    right: 6%;
    justify-content: flex-end;
  }
  .about-char-box {
    display: flex;
    align-items: flex-end;
    padding-bottom: 1rem;
  }
  .about-char-box__content {
    max-width: 28rem;
  }
  .about-char-box__icon {
    margin-right: 4rem;
    max-width: 40%;
    margin-bottom: 0;
  }
  .about-char-box__icon img {
    max-height: 14rem;
    min-width: 6rem;
  }
}
.banner {
  position: relative;
  min-height: 38rem;
  display: flex;
  align-items: flex-end;
  overflow: hidden;
}
.banner__cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  background-color: #2C3934;
  opacity: 0.25;
}
.banner__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}
.banner__content {
  padding: 5rem 2rem;
  position: relative;
  z-index: 2;
  color: #fff;
}
.banner__title {
  font-size: 3.2rem;
  line-height: 1.25em;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 1rem;
}
.banner__title b, .banner__title strong {
  text-decoration: underline;
  text-decoration-color: #48A681;
  text-underline-offset: 0.2em;
}
.banner__text {
  margin-bottom: 2rem;
}

@media screen and (min-width: 1260px) {
  .banner {
    min-height: 60rem;
    align-items: center;
  }
  .banner__content {
    padding: 10rem 12rem;
  }
  .banner__title {
    font-size: 7.2rem;
  }
  .banner.right .banner__content {
    margin-left: 33.33%;
  }
}
.adv-slider {
  overflow: hidden;
  position: relative;
}

.advs-box {
  color: #fff;
}
.advs-box__icon {
  height: 8rem;
  margin-bottom: 4rem;
}
.advs-box__icon img {
  max-height: 8rem;
}
.advs-box__value {
  font-size: 3.2rem;
  font-weight: 500;
  margin-bottom: 1rem;
}
.advs-box__text {
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 1.5em;
}

.slider-pagination {
  position: relative;
  bottom: 0 !important;
  padding-top: 4rem;
  display: flex;
  justify-content: center;
}
.slider-pagination .swiper-pagination-bullet {
  width: 26px;
  height: 26px;
  min-width: 26px;
  border: 2px solid transparent;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: transparent;
  opacity: 1;
  cursor: pointer;
}
.slider-pagination .swiper-pagination-bullet::after {
  content: "";
  width: 8px;
  height: 8px;
  background-color: #fff;
  border-radius: 50%;
}
.slider-pagination .swiper-pagination-bullet-active, .slider-pagination .swiper-pagination-bullet:hover {
  border-color: #fff;
}

@media screen and (min-width: 768px) {
  .advs-box__icon {
    height: 10rem;
    margin-bottom: 4rem;
  }
  .advs-box__icon img {
    max-height: 10rem;
  }
  .advs-box__value {
    font-size: 4.8rem;
  }
  .advs-box__text {
    font-size: 2rem;
  }
  .slider-pagination {
    padding-top: 8rem;
  }
}
.attr-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1.6rem;
  font-size: 1.6rem;
  font-weight: 400;
}
.attr-list__item {
  padding: 0 1.6rem;
  display: flex;
  align-items: center;
}
.attr-list__icon {
  margin-right: 2rem;
}
.attr-list__icon img {
  max-height: 3.6rem;
  width: auto;
  height: auto;
}

.box-about__image {
  margin-bottom: 3rem;
}
.box-about__desc {
  padding: 0 2rem;
}

@media screen and (min-width: 992px) {
  .box-about {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 -4rem;
  }
  .box-about__image {
    padding: 0 4rem;
    margin-bottom: 0;
  }
  .box-about__desc {
    padding: 0 4rem;
    width: 50rem;
    min-width: 50rem;
  }
  .box-about--reverse .box-about__image {
    order: 2;
  }
}
.post-list__item {
  margin-bottom: 5rem;
}

.post-box__image {
  width: 100%;
  display: block;
  position: relative;
  padding-bottom: 50%;
  margin-bottom: 3rem;
  overflow: hidden;
}
.post-box__image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  transition: all 1s;
}
.post-box__image:hover img {
  transform: scale(1.05);
}
.post-box__date {
  position: absolute;
  top: 1.5rem;
  left: 2rem;
}
@media screen and (min-width: 576px) {
  .post-box__date {
    top: 3rem;
    left: 4rem;
  }
}
.post-box__content {
  padding: 0 2rem;
}
.post-box__title {
  font-size: 2.4rem;
  line-height: 1.25em;
  margin-bottom: 1rem;
  font-weight: 400;
}
.post-box__text {
  margin-bottom: 2rem;
}

.post-date {
  padding: 1rem;
  background-color: #48A681;
  color: #fff;
  font-size: 1.2rem;
  line-height: 1.6rem;
  font-weight: 600;
}
.post-date .year {
  font-size: 1.4rem;
  line-height: 1.6rem;
}

@media screen and (min-width: 768px) {
  .post-date {
    padding: 1.4rem 2rem 1rem;
    font-size: 2rem;
    line-height: 3rem;
  }
  .post-date .year {
    font-size: 2.2rem;
    line-height: 3rem;
  }
}
@media screen and (min-width: 1260px) {
  .post-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -4rem;
  }
  .post-list__item {
    width: 50%;
    margin-bottom: 8rem;
    padding: 0 4rem;
  }
  .post-box__image {
    margin-bottom: 4rem;
  }
  .post-box__content {
    padding: 0;
  }
  .post-box__title {
    font-size: 3rem;
    margin-bottom: 2rem;
  }
  .post-box__text {
    margin-bottom: 3rem;
  }
}
.sn__header {
  padding: 2rem 2rem 0;
}
.sn__action {
  margin-bottom: 2rem;
}

.sp-header {
  display: flex;
  align-items: center;
  margin-bottom: 4rem;
}
.sp-header__date {
  margin-right: 1.2rem;
}
.sp-header__action {
  display: none;
}

.sp-more-header {
  display: flex;
  align-items: center;
  margin-bottom: 3rem;
  padding: 0 2rem;
}
.sp-more-header img {
  margin-right: 1.5rem;
}
@media screen and (max-width: 767px) {
  .sp-more-header img {
    max-width: 5rem;
  }
}

@media screen and (min-width: 1260px) {
  .sn__header {
    padding: 6rem 0 0;
  }
  .sn__action {
    margin-bottom: 1rem;
    padding-left: 13rem;
  }
  .sp-header {
    margin-bottom: 5rem;
  }
  .sp-header__date {
    margin-right: 4rem;
  }
  .sp-header__action {
    display: block;
    margin-left: auto;
  }
  .sp-more-header {
    margin-bottom: 6rem;
    padding: 0;
  }
  .sp-more-header img {
    margin-right: 3rem;
  }
}
.desc-info {
  padding: 6rem 2rem;
  background-color: #ECFBF5;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.desc-info__icon {
  margin-bottom: 4rem;
}
.desc-info__text {
  font-size: 1.8rem;
  font-weight: 500;
  font-style: italic;
  line-height: 1.5em;
  text-align: center;
}

@media screen and (min-width: 768px) {
  .desc-info {
    padding: 8rem;
    flex-direction: row;
  }
  .desc-info__icon {
    margin-right: 8rem;
    margin-bottom: 0;
  }
  .desc-info__text {
    font-size: 2.4rem;
    text-align: left;
  }
}
.hero {
  position: relative;
  color: #fff;
  margin-bottom: 4rem;
}
.hero__content {
  min-height: 25rem;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
  background-color: #48A681;
}
.hero__row {
  display: flex;
  align-items: flex-start;
  padding: 4rem 2rem;
}
.hero__icon {
  margin-right: 3rem;
}
.hero__title {
  margin-bottom: 3rem;
}
.hero__text {
  font-size: 1.8rem;
  font-weight: 300;
  line-height: 1.5em;
  max-width: 35rem;
}
@media screen and (min-width: 768px) {
  .hero__text {
    font-size: 2.4rem;
  }
}
.hero__text p {
  margin-bottom: 2rem;
}
@media screen and (min-width: 768px) {
  .hero__text p {
    margin-bottom: 3rem;
  }
}
.hero__text p:last-child {
  margin-bottom: 0;
}
.hero__image {
  position: relative;
  width: 100%;
  padding-bottom: 60%;
}
.hero__image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

@media screen and (max-width: 575px) {
  .hero__icon {
    margin-right: 2rem;
    max-width: 4rem;
  }
}
@media screen and (min-width: 1260px) {
  .hero {
    padding-bottom: 6rem;
    margin-bottom: 8rem;
  }
  .hero::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 55%;
    height: 49rem;
    background-color: #48A681;
    clip-path: polygon(0 0, 100% 0, 85% 100%, 0% 100%);
  }
  .hero__content {
    width: 40%;
    min-height: 49rem;
    background: transparent;
  }
  .hero__image {
    position: absolute;
    top: 6rem;
    right: 0;
    width: 60%;
    height: 49rem;
    clip-path: polygon(15% 0, 100% 0, 100% 100%, 0% 100%);
    padding-bottom: 0;
  }
  .hero__row {
    padding: 0;
  }
}
.info-box {
  position: relative;
}
.info-box::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 50%;
  background: linear-gradient(0deg, rgb(72, 166, 129) 0%, rgba(72, 166, 129, 0) 100%);
}
.info-box__content {
  position: absolute;
  z-index: 2;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 4rem 2rem;
  color: #fff;
  display: flex;
  align-items: center;
}
.info-box__content img {
  max-width: 3rem;
  margin-right: 2rem;
}
.info-box__text {
  font-size: 1.8rem;
  line-height: 1.25em;
  font-weight: 400;
}

@media screen and (min-width: 768px) {
  .info-box__text {
    font-size: 3.6rem;
  }
  .info-box__content {
    padding: 4rem;
  }
  .info-box__content img {
    max-width: 6rem;
    margin-right: 3rem;
  }
}
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
}
.pagination__btn {
  width: 5rem;
  min-width: 5rem;
  height: 5rem;
  background-color: #ECFBF5;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.pagination__btn.prev {
  transform: rotate(180deg);
}
.pagination__btn svg path {
  stroke: #2C3934;
}
.pagination__btn:hover {
  background-color: #48A681;
}
.pagination__btn:hover svg path {
  stroke: #fff;
}

.pagination-pages {
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  font-weight: 400;
}
.pagination-pages__item {
  padding: 0 0.3rem;
}
.pagination-pages__link {
  width: 4rem;
  min-width: 4rem;
  height: 4rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding-top: 3px;
}
.pagination-pages__link.current, .pagination-pages__link:hover {
  background-color: #48A681;
  color: #fff;
}

.header {
  width: 100%;
  position: absolute;
  z-index: 11;
  transition: all 0.25s ease-in-out;
  padding: 0 2rem;
}
.header__row {
  display: flex;
  align-items: center;
  height: 9.6rem;
}
.header__nav {
  display: flex;
  align-items: center;
  pointer-events: all;
}

.brand img {
  max-width: 9rem;
  width: auto;
  height: auto;
}

@media screen and (max-width: 1259.99px) {
  .header {
    height: 100vh;
    min-height: 60rem;
    overflow: hidden;
    pointer-events: none;
  }
  .header__row {
    pointer-events: all;
  }
}
@media screen and (min-width: 1260px) {
  .brand img {
    max-width: 14rem;
  }
  .header {
    padding: 0;
  }
  .header__row {
    height: 16rem;
  }
}
.nav {
  width: 100%;
}
.hamburger {
  width: 40px;
  height: 40px;
  z-index: 100;
  position: relative;
  cursor: pointer;
  margin-left: auto;
  overflow: hidden;
}
.hamburger svg {
  position: absolute;
  right: -15%;
  height: 100%;
  transform-origin: top right;
}
.hamburger path {
  fill: none;
  stroke: #2C3934;
  stroke-width: 3;
  stroke-linecap: round;
  stroke-linejoin: round;
  --length: 24;
  --offset: -38;
  stroke-dasharray: var(--length) var(--total-length);
  stroke-dashoffset: var(--offset);
  transition: all 0.25s ease-in-out;
}
.hamburger path:nth-child(1), .hamburger path:nth-child(3) {
  --total-length: 126.64183044433594;
}
.hamburger path:nth-child(2) {
  --total-length: 70;
}

@media screen and (max-width: 1259.99px) {
  .nav {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100vh;
    background-color: #fff;
    opacity: 0;
    transform: translateX(100%);
    transition: all 0.25s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 3rem;
    padding-bottom: 4rem;
    border-bottom: 2px solid #48A681;
  }
  .nav__langs {
    order: 1;
    margin-bottom: 2rem;
  }
  .nav .menu {
    order: 2;
    margin-bottom: 1rem;
  }
  .nav__socials {
    order: 3;
  }
  .nav-toggled .nav {
    transform: translateX(0);
    opacity: 1;
  }
  .nav-toggled .hamburger path {
    stroke: #2C3934;
  }
  .nav-toggled .hamburger path:nth-child(1), .nav-toggled .hamburger path:nth-child(3) {
    --length: 22.627416998;
    --offset: -94.1149185097;
  }
  .nav-toggled .hamburger path:nth-child(2) {
    --length: 0;
    --offset: -50;
  }
}
@media screen and (min-width: 1260px) {
  .nav {
    display: flex;
    justify-content: flex-end;
    padding-right: 4rem;
  }
  .nav__langs {
    margin-left: 2rem;
  }
  .nav__socials {
    position: absolute;
    top: 6rem;
    right: 2rem;
  }
}
@media screen and (min-width: 1260px) and (min-width: 1860px) {
  .nav__socials {
    right: 3rem;
  }
}
@media screen and (min-width: 1260px) {
  .hamburger {
    display: none;
  }
}
@media screen and (min-width: 1560px) {
  .nav {
    padding-right: 0;
  }
  .nav__langs {
    margin-left: 6rem;
  }
}
.menu {
  display: flex;
  align-items: center;
}
.menu__item {
  position: relative;
  margin-right: 1rem;
}
.menu__item a {
  font-size: 1.6rem;
  font-weight: 300;
  padding: 1.5rem 1.2rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #2C3934;
}
.menu__item a:hover {
  background-color: #48A681;
  color: #fff;
}
.menu__item.active a {
  background-color: #48A681;
  color: #fff;
}

@media screen and (max-width: 1259.99px) {
  .menu {
    flex-direction: column;
    align-items: center;
  }
}
@media screen and (min-width: 1560px) {
  .menu__item a {
    font-size: 1.6rem;
    font-weight: 300;
    padding: 2.4rem 2.4rem;
  }
}
.lang-switcher {
  display: flex;
  align-items: center;
}
.lang-switcher a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 1.6rem;
  font-weight: 300;
  position: relative;
  padding-top: 3px;
}
.lang-switcher a::after {
  content: "";
  position: absolute;
  top: 36px;
  left: 12px;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 10px 9px 0 9px;
  border-color: transparent transparent transparent transparent;
}
.lang-switcher a:hover {
  background-color: #48A681;
  color: #fff;
}
.lang-switcher .lang-item {
  margin-left: 1rem;
}
.lang-switcher .lang-item.current-lang a {
  background-color: #48A681;
  color: #fff;
}
.lang-switcher .lang-item.current-lang a::after {
  border-color: #48A681 transparent transparent transparent;
}

.social-media {
  display: flex;
  align-items: center;
}
.social-media__item {
  margin-bottom: 1rem;
}
.social-media__link {
  display: inline-flex;
  padding: 1rem;
}
.social-media__link img, .social-media__link svg {
  max-width: 2rem;
  max-height: 2rem;
  transition: all 0.3s;
}
.social-media__link:hover img, .social-media__link:hover svg {
  transform: scale(1.2);
}

@media screen and (min-width: 1260px) {
  .social-media {
    flex-direction: column;
  }
}
.th-hero {
  position: relative;
}
.th-hero__scroll {
  position: absolute;
  left: 50%;
  bottom: -1px;
  transform: translate(-50%, 50%);
  z-index: 2;
}

.btn-scroll {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-scroll::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 0 50px 50px 50px;
  border-color: transparent transparent #fff transparent;
}
.btn-scroll img {
  position: relative;
}

.th-about {
  padding: 0 2rem;
}
.th-about__header {
  text-align: center;
  max-width: 86rem;
  margin: 0 auto 3rem auto;
}
.th-about__content {
  margin-bottom: 3rem;
}
.th-about__footer {
  max-width: 86rem;
  margin: 0 auto 4rem auto;
  text-align: center;
}
@media screen and (max-width: 991.99px) {
  .th-about__footer {
    display: none;
  }
}
.th-about__address {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.8rem;
  line-height: 1.5em;
  font-weight: 400;
}
.th-about__address img {
  margin-right: 3rem;
}

@media screen and (max-width: 575px) {
  .th-about__address {
    flex-direction: column;
    text-align: center;
  }
  .th-about__address img {
    margin-right: 0;
    margin-bottom: 2rem;
  }
}
@media screen and (min-width: 1260px) {
  .th-about {
    padding: 0;
  }
  .th-about__address {
    font-size: 2rem;
  }
}
.th-adv {
  padding: 6rem 2rem 4rem;
  background: rgb(72, 166, 129);
  background: linear-gradient(135deg, rgb(72, 166, 129) 0%, rgb(212, 205, 148) 100%);
}
.th-adv__header {
  display: flex;
  align-items: center;
  padding-bottom: 6rem;
  color: #fff;
  position: relative;
}
.th-adv__header img {
  margin-right: 2rem;
}
.th-adv__header::after {
  content: "";
  position: absolute;
  top: calc(100% - 2rem);
  left: 4rem;
  width: 12rem;
  height: 12rem;
  border-radius: 50%;
  background-color: #25815D;
}
.th-adv__dec {
  position: absolute;
  top: -2rem;
  right: -4rem;
}

@media screen and (max-width: 575px) {
  .th-adv__header img {
    margin-right: 1rem;
    max-height: 2.4rem;
  }
  .th-adv__dec {
    top: 2rem;
    right: -8rem;
    opacity: 0.3;
  }
}
@media screen and (min-width: 1260px) {
  .th-adv {
    padding: 44rem 0 40rem;
    margin-top: -28rem;
    margin-bottom: -28rem;
  }
  .th-adv__header {
    margin-right: 6rem;
    padding-bottom: 12rem;
  }
  .th-adv__dec {
    top: -4rem;
    right: 12rem;
  }
}
.th-offer__header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 2rem;
  margin-bottom: 3rem;
  font-size: 3.2rem;
  font-weight: 600;
  text-transform: uppercase;
}
.th-offer__header img {
  margin-right: 1.6rem;
  height: 2.5rem;
}

@media screen and (min-width: 1260px) {
  .th-offer__header {
    display: none;
  }
}
#char_about .svg-elem-1 {
  stroke-dashoffset: 1718.2810058594px;
  stroke-dasharray: 1718.2810058594px;
  fill: transparent;
  transition: stroke-dashoffset 1s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0s, fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s;
}
#char_about .svg-elem-2 {
  stroke-dashoffset: 1718.4376220703px;
  stroke-dasharray: 1718.4376220703px;
  fill: transparent;
  transition: stroke-dashoffset 1s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.12s, fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.9s;
}
#char_about .svg-elem-3 {
  stroke-dashoffset: 1572.7963267949px;
  stroke-dasharray: 1572.7963267949px;
  fill: transparent;
  transition: stroke-dashoffset 1s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.24s, fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1s;
}
#char_about .svg-elem-4 {
  stroke-dashoffset: 1569.6547341413px;
  stroke-dasharray: 1569.6547341413px;
  transition: stroke-dashoffset 1s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.36s, fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.1s;
}
#char_about .svg-elem-5 {
  stroke-dashoffset: 1264.9202467431px;
  stroke-dasharray: 1264.9202467431px;
  fill: transparent;
  transition: stroke-dashoffset 1s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.48s, fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2s;
}
#char_about .svg-elem-6 {
  stroke-dashoffset: 506px;
  stroke-dasharray: 506px;
  transition: stroke-dashoffset 1s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.6s, fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.3s;
}
#char_about .svg-elem-7 {
  stroke-dashoffset: 494px;
  stroke-dasharray: 494px;
  transition: stroke-dashoffset 1s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.72s, fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.4s;
}
#char_about .svg-elem-8 {
  stroke-dashoffset: 494px;
  stroke-dasharray: 494px;
  transition: stroke-dashoffset 1s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.84s, fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.5s;
}
#char_about .svg-elem-9 {
  stroke-dashoffset: 494px;
  stroke-dasharray: 494px;
  transition: stroke-dashoffset 1s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.96s, fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.6s;
}
#char_about .svg-elem-10 {
  stroke-dashoffset: 202px;
  stroke-dasharray: 202px;
  fill: transparent;
  transition: stroke-dashoffset 1s cubic-bezier(0.68, -0.55, 0.265, 1.55) 1.08s, fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.7s;
}
#char_about .svg-elem-11 {
  stroke-dashoffset: 202px;
  stroke-dasharray: 202px;
  fill: transparent;
  transition: stroke-dashoffset 1s cubic-bezier(0.68, -0.55, 0.265, 1.55) 1.2s, fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.8s;
}
#char_about .svg-elem-12 {
  stroke-dashoffset: 190px;
  stroke-dasharray: 190px;
  fill: transparent;
  transition: stroke-dashoffset 1s cubic-bezier(0.68, -0.55, 0.265, 1.55) 1.32s, fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.9s;
}
#char_about .svg-elem-13 {
  stroke-dashoffset: 190px;
  stroke-dasharray: 190px;
  fill: transparent;
  transition: stroke-dashoffset 1s cubic-bezier(0.68, -0.55, 0.265, 1.55) 1.44s, fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2s;
}
#char_about .svg-elem-14 {
  stroke-dashoffset: 52.2654824574px;
  stroke-dasharray: 52.2654824574px;
  fill: transparent;
  transition: stroke-dashoffset 1s cubic-bezier(0.68, -0.55, 0.265, 1.55) 1.56s, fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.1s;
}
#char_about .svg-elem-15 {
  stroke-dashoffset: 52.2654824574px;
  stroke-dasharray: 52.2654824574px;
  fill: transparent;
  transition: stroke-dashoffset 1s cubic-bezier(0.68, -0.55, 0.265, 1.55) 1.68s, fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.2s;
}
#char_about .svg-elem-16 {
  stroke-dashoffset: 52.2654824574px;
  stroke-dasharray: 52.2654824574px;
  fill: transparent;
  transition: stroke-dashoffset 1s cubic-bezier(0.68, -0.55, 0.265, 1.55) 1.8s, fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.3s;
}
#char_about .svg-elem-17 {
  stroke-dashoffset: 52.2654824574px;
  stroke-dasharray: 52.2654824574px;
  fill: transparent;
  transition: stroke-dashoffset 1s cubic-bezier(0.68, -0.55, 0.265, 1.55) 1.92s, fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.4s;
}
#char_about .svg-elem-18 {
  stroke-dashoffset: 123px;
  stroke-dasharray: 123px;
  transition: stroke-dashoffset 1s cubic-bezier(0.68, -0.55, 0.265, 1.55) 2.04s, fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.5s;
}
#char_about .svg-elem-19 {
  stroke-dashoffset: 52px;
  stroke-dasharray: 52px;
  transition: stroke-dashoffset 1s cubic-bezier(0.68, -0.55, 0.265, 1.55) 2.16s, fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.6s;
}
#char_about .svg-elem-20 {
  stroke-dashoffset: 908px;
  stroke-dasharray: 908px;
  fill: transparent;
  transition: stroke-dashoffset 1s cubic-bezier(0.68, -0.55, 0.265, 1.55) 2.28s, fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.7s;
}

.about-char__item {
  opacity: 0;
}

.th-about__content.aos-animate .about-char__item, .th-about__content:not(.aos-init) .about-char__item {
  transition: opacity 0.7s cubic-bezier(0.47, 0, 0.745, 0.715);
  transition-delay: 1.8s;
  opacity: 1;
}
.th-about__content.aos-animate #char_about .svg-elem-1, .th-about__content:not(.aos-init) #char_about .svg-elem-1 {
  stroke-dashoffset: 0;
  fill: rgb(239, 247, 244);
}
.th-about__content.aos-animate #char_about .svg-elem-2, .th-about__content:not(.aos-init) #char_about .svg-elem-2 {
  stroke-dashoffset: 0;
  fill: rgb(239, 247, 244);
}
.th-about__content.aos-animate #char_about .svg-elem-3, .th-about__content:not(.aos-init) #char_about .svg-elem-3 {
  stroke-dashoffset: 0;
  fill: rgb(72, 166, 129);
}
.th-about__content.aos-animate #char_about .svg-elem-4, .th-about__content:not(.aos-init) #char_about .svg-elem-4 {
  stroke-dashoffset: 0;
}
.th-about__content.aos-animate #char_about .svg-elem-5, .th-about__content:not(.aos-init) #char_about .svg-elem-5 {
  stroke-dashoffset: 0;
  fill: rgb(255, 255, 255);
}
.th-about__content.aos-animate #char_about .svg-elem-6, .th-about__content:not(.aos-init) #char_about .svg-elem-6 {
  stroke-dashoffset: 0;
}
.th-about__content.aos-animate #char_about .svg-elem-7, .th-about__content:not(.aos-init) #char_about .svg-elem-7 {
  stroke-dashoffset: 0;
}
.th-about__content.aos-animate #char_about .svg-elem-8, .th-about__content:not(.aos-init) #char_about .svg-elem-8 {
  stroke-dashoffset: 0;
}
.th-about__content.aos-animate #char_about .svg-elem-9, .th-about__content:not(.aos-init) #char_about .svg-elem-9 {
  stroke-dashoffset: 0;
}
.th-about__content.aos-animate #char_about .svg-elem-10, .th-about__content:not(.aos-init) #char_about .svg-elem-10 {
  stroke-dashoffset: 0;
  fill: rgb(72, 166, 129);
}
.th-about__content.aos-animate #char_about .svg-elem-11, .th-about__content:not(.aos-init) #char_about .svg-elem-11 {
  stroke-dashoffset: 0;
  fill: rgb(72, 166, 129);
}
.th-about__content.aos-animate #char_about .svg-elem-12, .th-about__content:not(.aos-init) #char_about .svg-elem-12 {
  stroke-dashoffset: 0;
  fill: rgb(72, 166, 129);
}
.th-about__content.aos-animate #char_about .svg-elem-13, .th-about__content:not(.aos-init) #char_about .svg-elem-13 {
  stroke-dashoffset: 0;
  fill: rgb(72, 166, 129);
}
.th-about__content.aos-animate #char_about .svg-elem-14, .th-about__content:not(.aos-init) #char_about .svg-elem-14 {
  stroke-dashoffset: 0;
  fill: rgb(72, 166, 129);
}
.th-about__content.aos-animate #char_about .svg-elem-15, .th-about__content:not(.aos-init) #char_about .svg-elem-15 {
  stroke-dashoffset: 0;
  fill: rgb(72, 166, 129);
}
.th-about__content.aos-animate #char_about .svg-elem-16, .th-about__content:not(.aos-init) #char_about .svg-elem-16 {
  stroke-dashoffset: 0;
  fill: rgb(72, 166, 129);
}
.th-about__content.aos-animate #char_about .svg-elem-17, .th-about__content:not(.aos-init) #char_about .svg-elem-17 {
  stroke-dashoffset: 0;
  fill: rgb(72, 166, 129);
}
.th-about__content.aos-animate #char_about .svg-elem-18, .th-about__content:not(.aos-init) #char_about .svg-elem-18 {
  stroke-dashoffset: 0;
}
.th-about__content.aos-animate #char_about .svg-elem-19, .th-about__content:not(.aos-init) #char_about .svg-elem-19 {
  stroke-dashoffset: 0;
}
.th-about__content.aos-animate #char_about .svg-elem-20, .th-about__content:not(.aos-init) #char_about .svg-elem-20 {
  stroke-dashoffset: 0;
  fill: url("#pattern");
}

.t-products {
  padding-top: 2rem;
  position: relative;
  padding-bottom: 54rem;
}
.t-products__header {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  padding: 0 2rem;
}
.t-products__header img {
  margin-right: 2rem;
}
.t-products__about {
  padding: 2rem;
}
.t-products__contact {
  padding: 0 2rem;
  margin-bottom: 4rem;
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 1.5em;
}
.t-products__contact .btn {
  margin-top: 2rem;
}
.t-products__info {
  margin-bottom: 4rem;
}
.t-products__list {
  padding: 0 2rem;
}

@media screen and (max-width: 1259.99px) {
  .t-products__banner {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
  }
}
@media screen and (min-width: 1260px) {
  .t-products {
    padding-top: 8rem;
    padding-bottom: 0;
    display: flex;
  }
  .t-products__nav {
    width: 25%;
    max-width: 400px;
    margin-bottom: 2rem;
  }
  .t-products__content {
    padding-left: 4rem;
    flex-grow: 1;
  }
  .t-products__header {
    display: block;
    min-height: 14rem;
    padding: 0;
    margin-bottom: 0;
  }
  .t-products__header img {
    display: none;
  }
  .t-products__about {
    min-height: 14rem;
    padding: 0 0 4rem 0;
  }
  .t-products__text {
    max-width: 60rem;
    width: 50%;
    padding-right: 4rem;
  }
  .t-products__list {
    padding: 0;
  }
  .t-products__contact {
    position: absolute;
    top: 8rem;
    right: 0;
    width: 35%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    margin-bottom: 0;
  }
  .t-products__contact .btn {
    margin-top: 0;
  }
  .t-products__contact::before {
    content: "";
    width: 1px;
    height: 76px;
    background-color: #C5C5C5;
  }
}
.product-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1.6rem;
}
.product-list__item {
  width: 100%;
  padding: 0 1.6rem;
  margin-bottom: 5rem;
}
@media screen and (min-width: 576px) {
  .product-list__item {
    width: 50%;
  }
}
@media screen and (min-width: 992px) {
  .product-list__item {
    width: 33.33%;
  }
}

.product-box {
  display: block;
  width: 100%;
}
.product-box__image {
  position: relative;
  padding-bottom: 110%;
  margin-bottom: 2rem;
  overflow: hidden;
}
.product-box__image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  transition: all 1s;
}
.product-box__title {
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.25em;
  margin-bottom: 2rem;
}
.product-box:hover .product-box__image img {
  transform: scale(1.05);
}
.product-box:hover .product-box__title {
  text-decoration: underline;
}

.products-nav {
  width: 100%;
  background-color: #ECFBF5;
  position: relative;
}
.products-nav__header {
  display: none;
}
.products-nav__current {
  font-size: 1.6rem;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem;
  min-height: 5.6rem;
  background-color: #48A681;
  color: #fff;
}

.products-menu {
  font-size: 1.6rem;
  font-weight: 400;
  background-color: #ECFBF5;
}
.products-menu__item {
  margin-bottom: 0.4rem;
}
.products-menu__link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
  min-height: 5.6rem;
}
.products-menu__link img {
  opacity: 0;
}
.products-menu__link:hover, .products-menu__link.active {
  background-color: #48A681;
  color: #fff;
}
.products-menu__link:hover img, .products-menu__link.active img {
  opacity: 1;
}

@media screen and (max-width: 1259.99px) {
  .products-nav__menu {
    position: absolute;
    left: 0;
    width: 100%;
    top: 100%;
    z-index: 2;
    display: none;
  }
  .products-nav:hover .products-nav__menu {
    display: block;
  }
  .products-menu__link.active {
    display: none;
  }
}
@media screen and (min-width: 1260px) {
  .products-nav {
    padding-bottom: 2rem;
  }
  .products-nav__header {
    display: flex;
    align-items: center;
    padding: 4rem 4.6rem 2rem;
    font-size: 2.4rem;
    font-weight: 600;
    text-transform: uppercase;
  }
  .products-nav__header img {
    margin-right: 2rem;
  }
  .products-nav__current {
    display: none;
  }
  .products-menu__link {
    padding: 1rem 4.6rem;
  }
}
.catalog-box {
  padding: 2rem;
  background-color: #ECFBF5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.catalog-box__text {
  display: none;
  padding: 2rem 0;
  font-size: 1.6rem;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.25em;
}

.btn-download {
  display: inline-flex;
  align-items: center;
  font-size: 1.6rem;
  line-height: 1.25em;
  font-weight: 600;
  text-transform: uppercase;
  color: #48A681;
}
.btn-download:hover {
  text-decoration: underline;
  text-underline-offset: 0.2em;
}
.btn-download img {
  margin-left: 1.4rem;
}
@media screen and (max-width: 575px) {
  .btn-download img {
    max-width: 2rem;
  }
}

@media screen and (min-width: 1260px) {
  .catalog-box {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 3rem;
  }
  .catalog-box__text {
    display: block;
    font-size: 1.8rem;
    padding: 0;
  }
  .btn-download {
    font-size: 1.8rem;
  }
}
.sp-about {
  margin-top: -4rem;
}
.sp-about__header {
  margin-bottom: 2rem;
}
.sp-about__title {
  font-size: 2.4rem;
  line-height: 1.25em;
  font-weight: 500;
  margin-bottom: 2rem;
}
.sp-about__item {
  margin-bottom: 4rem;
}

@media screen and (max-width: 1259.99px) {
  .single-product .t-products__about {
    display: none;
  }
}
@media screen and (min-width: 1260px) {
  .sp-about {
    padding: 0;
    margin-top: 0;
  }
  .sp-about__header {
    margin-bottom: 4rem;
  }
  .sp-about__title {
    font-size: 3rem;
    margin-bottom: 3rem;
  }
  .sp-about__item {
    margin-bottom: 6rem;
  }
}
.tc-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 -1.5rem;
  padding: 0 2rem;
}
.tc-list__item {
  width: 32rem;
  padding: 0 1.5rem;
  margin-bottom: 3rem;
}

.tc-box__header {
  font-size: 1.6rem;
  font-weight: 400;
  color: #CECECE;
  padding-bottom: 1.5rem;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid #48A681;
}
.tc-box__text {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.25em;
}
.tc-box__text p {
  margin-bottom: 1.5rem;
}
.tc-box__text a[href^="tel:"] {
  display: inline-block;
  margin-bottom: 1rem;
  font-size: 2.4rem;
  font-weight: 500;
  color: #48A681;
  text-underline-offset: 0.1em;
}
.tc-box__text a[href^="tel:"]:hover {
  text-decoration: underline;
}
.tc-box__text a[href^="mailto:"] {
  display: inline-block;
  margin-bottom: 1rem;
  font-size: 1.6rem;
  font-weight: 400;
  text-decoration: underline;
  margin-bottom: 1rem;
  text-underline-offset: 0.1em;
}
.tc-box__text a[href^="mailto:"]:hover {
  color: #48A681;
}

@media screen and (min-width: 768px) {
  .tc-box__header {
    font-size: 2rem;
    padding-bottom: 3rem;
    margin-bottom: 3rem;
  }
  .tc-box__text {
    font-size: 2.2rem;
  }
  .tc-box__text p {
    margin-bottom: 2rem;
  }
  .tc-box__text a[href^="tel:"] {
    font-size: 2.6rem;
  }
  .tc-box__text a[href^="mailto:"] {
    font-size: 1.8rem;
  }
}
@media screen and (min-width: 992px) {
  .tc-list {
    margin: 0 -3rem;
  }
  .tc-list__item {
    width: 33.33%;
    padding: 0 3rem;
    margin-bottom: 6rem;
  }
}
@media screen and (min-width: 1260px) {
  .tc-list {
    padding: 0;
  }
}
.tc-map {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  margin-bottom: 3rem;
}
@media screen and (min-width: 768px) {
  .tc-map {
    padding-bottom: 60%;
  }
}
@media screen and (min-width: 1260px) {
  .tc-map {
    padding-bottom: 40%;
    margin-bottom: 4rem;
  }
}
.tc-map iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.tc-data {
  padding: 0 2rem;
}
.tc-data .btn-contact {
  margin-bottom: 2rem;
}

@media screen and (min-width: 1260px) {
  .tc-data {
    padding: 0;
  }
  .tc-data .btn-contact {
    margin-bottom: 0;
  }
  .tc-data__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.tc-form {
  padding: 0 2rem;
}
.tc-form__title {
  font-size: 2.4rem;
  line-height: 1.25em;
  font-weight: 400;
  margin-bottom: 3rem;
}

@media screen and (min-width: 1260px) {
  .tc-form {
    padding: 0;
  }
  .tc-form__title {
    font-size: 3rem;
    margin-bottom: 5rem;
  }
}
.job-list__item:hover {
  background-color: #ECFBF5;
}

.job-box {
  padding: 0 2rem;
}
.job-box__header {
  display: flex;
  align-items: center;
  padding: 3rem 0;
  border-bottom: 1px solid #D2D2D2;
  cursor: pointer;
}
.job-box__title {
  font-size: 2.4rem;
  line-height: 1.25em;
  font-weight: 400;
}
.job-box__place {
  display: inline-flex;
  align-items: center;
  font-size: 1.8rem;
  font-weight: 400;
  min-width: 30rem;
  margin-left: auto;
}
.job-box__place img {
  margin-right: 1rem;
  max-width: 2rem;
}
.job-box__action {
  margin-left: auto;
}
.job-box__content {
  display: none;
}
.job-box__col {
  margin-bottom: 4rem;
}
.job-box__contact {
  margin-top: 4rem;
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 1.5em;
}
.job-box__contact a {
  margin-top: 2rem;
}
.job-box__tag {
  font-size: 2.4rem;
  line-height: 1.25em;
  font-weight: 400;
  margin-bottom: 4rem;
  text-transform: uppercase;
}
.job-box.active {
  background-color: #ECFBF5;
}
.job-box.active .job-box__header {
  border-color: transparent;
}
.job-box.active .job-box__content {
  display: block;
}
.job-box.active .btn-expand {
  transform: rotate(-180deg);
}

@media screen and (max-width: 1259.99px) {
  .job-box__header {
    flex-wrap: wrap;
    align-items: flex-start;
  }
  .job-box__title {
    max-width: calc(100% - 5rem);
  }
  .job-box__place {
    order: 3;
    width: 100%;
    padding-top: 1rem;
  }
  .job-box__contact {
    padding-bottom: 4rem;
  }
}
@media screen and (min-width: 1260px) {
  .job-box {
    padding: 0;
  }
  .job-box__title {
    font-size: 3.6rem;
  }
  .job-box__place {
    font-size: 2.4rem;
  }
  .job-box__place img {
    max-width: 3rem;
    margin-right: 2rem;
  }
  .job-box__action {
    margin-left: 10rem;
  }
  .job-box__content {
    flex-wrap: wrap;
    margin: 0 -4rem;
    padding: 4rem 0;
    display: none;
  }
  .job-box__apply {
    width: 30%;
    padding: 0 4rem;
  }
  .job-box__row {
    width: 70%;
    display: flex;
    flex-wrap: wrap;
    order: 2;
  }
  .job-box__col {
    width: 50%;
    padding: 0 4rem;
    margin-bottom: 0;
  }
  .job-box__contact {
    margin-top: 8rem;
  }
  .job-box.active .job-box__content {
    display: flex;
  }
}
.s-apply {
  padding-top: 2rem;
}
.s-apply__header {
  padding: 0 2rem;
  margin-bottom: 3rem;
}
.s-apply__title {
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.25em;
  text-transform: uppercase;
}
.s-apply__name {
  font-size: 2.4rem;
  line-height: 1.25em;
  font-weight: 600;
  color: #48A681;
}
.s-apply__row {
  padding: 0 2rem;
}
.s-apply__form {
  margin-bottom: 4rem;
}

.form-apply-submit {
  display: flex;
  justify-content: center;
}

.apply-more-header {
  padding: 0 2rem;
  margin-bottom: 3rem;
}

@media screen and (min-width: 768px) {
  .s-apply__title {
    font-size: 6.4rem;
  }
  .s-apply__name {
    font-size: 6.4rem;
  }
  .form-apply {
    display: flex;
    flex-wrap: wrap;
  }
  .form-apply .form-field {
    width: 50%;
  }
  .form-apply .form-field--full {
    width: 100%;
  }
  .form-apply-submit {
    justify-content: flex-end;
  }
}
@media screen and (min-width: 1260px) {
  .s-apply {
    padding-top: 4rem;
  }
  .s-apply__header {
    padding: 0;
    margin-bottom: 6rem;
  }
  .s-apply__row {
    display: flex;
    padding: 0;
  }
  .s-apply__form {
    width: 60%;
    order: 2;
    padding-left: 8rem;
    margin-bottom: 0;
  }
  .s-apply__info {
    width: 40%;
  }
  .apply-more-header {
    padding: 0;
    margin-bottom: 4rem;
  }
}
.ta-about__desc {
  margin-bottom: 4rem;
  padding: 0 2rem;
}
.ta-about__text {
  margin-bottom: 4rem;
}

@media screen and (min-width: 1260px) {
  .ta-about__row {
    display: flex;
    align-items: center;
    margin: 0 -4rem;
  }
  .ta-about__desc {
    width: 40%;
    padding: 0 4rem;
    margin-bottom: 0;
  }
  .ta-about__image {
    width: 60%;
    padding: 0 4rem;
  }
  .ta-about__text {
    margin-bottom: 4rem;
  }
  .ta-about--reverse .ta-about__desc {
    order: 2;
  }
}
.ta-adv {
  padding: 6rem 2rem 4rem;
  background: rgb(72, 166, 129);
  background: linear-gradient(135deg, rgb(72, 166, 129) 0%, rgb(212, 205, 148) 100%);
}

@media screen and (min-width: 1260px) {
  .ta-adv {
    padding: 12rem 0 8rem;
  }
}
.prop-box {
  padding: 5rem 2rem;
  background: rgb(72, 166, 129);
  background: linear-gradient(135deg, rgb(72, 166, 129) 0%, rgb(212, 205, 148) 100%);
  color: #ffffff;
  position: relative;
}
.prop-box__header {
  display: flex;
  justify-content: center;
  margin-bottom: 5rem;
  position: relative;
}
.prop-box__dec_1 {
  position: absolute;
  top: 2rem;
  right: 1rem;
}
.prop-box__dec_2 {
  display: none;
}

.prop-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 -2rem;
  position: relative;
}
.prop-list__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 45rem;
  padding: 0 2rem;
  margin-bottom: 4rem;
  text-align: center;
}
.prop-list__icon {
  margin-bottom: 2rem;
}
.prop-list__text {
  font-size: 1.8rem;
  line-height: 1.75em;
  font-weight: 400;
}

@media screen and (min-width: 992px) {
  .prop-list {
    margin: 0 -4rem;
    justify-content: space-between;
  }
  .prop-list__item {
    padding: 0 4rem;
  }
  .prop-list__icon {
    margin-bottom: 4rem;
  }
}
@media screen and (min-width: 1260px) {
  .prop-box {
    padding: 9rem 12rem;
  }
  .prop-box__dec_2 {
    display: block;
    position: absolute;
    top: 5rem;
    left: 10rem;
  }
  .prop-box__dec_1 {
    top: 16rem;
    right: 10rem;
  }
}
.hobby-wrapper {
  padding-top: 5rem;
  background: rgb(72, 166, 129);
  background: linear-gradient(135deg, rgb(72, 166, 129) 0%, rgb(212, 205, 148) 100%);
  color: #ffffff;
  padding-bottom: 2rem;
  position: relative;
  overflow: hidden;
}

.hobby-hero {
  position: relative;
  padding: 0 2rem;
}
.hobby-hero__dec_1 {
  position: absolute;
  top: 0;
  right: 50%;
}
.hobby-hero__dec_2 {
  position: absolute;
  top: 5rem;
  right: 0;
}
.hobby-hero__desc {
  margin-bottom: 4rem;
  position: relative;
}
.hobby-hero__image {
  display: flex;
  justify-content: center;
}
.hobby-hero__image img {
  filter: drop-shadow(0px 50px 35px #55A184);
}
@media screen and (max-width: 575.99px) {
  .hobby-hero__image img {
    max-width: 16rem;
  }
}
@media screen and (max-width: 1259.99px) {
  .hobby-hero__image img {
    max-width: 26rem;
  }
}
.hobby-hero__title {
  margin-bottom: 4rem;
  position: relative;
}
.hobby-hero__text {
  font-size: 2.4rem;
  line-height: 1.5em;
  font-weight: 400;
  text-transform: uppercase;
  position: relative;
}

@media screen and (min-width: 768px) {
  .hobby-hero {
    display: flex;
    align-items: center;
  }
  .hobby-hero__desc {
    width: 50%;
    margin-bottom: 0;
  }
  .hobby-hero__image {
    width: 50%;
  }
}
@media screen and (min-width: 1260px) {
  .hobby-wrapper {
    padding-top: 12rem;
    border-radius: 0 20rem 0 0;
  }
  .hobby-hero {
    padding: 0;
  }
}
.hobby-events {
  padding: 0 2rem;
}
.hobby-events__item {
  margin-bottom: 5rem;
}
.hobby-events__header {
  display: flex;
  align-items: center;
  margin-bottom: 4rem;
}
.hobby-events__header img {
  margin-right: 4rem;
}
@media screen and (max-width: 575px) {
  .hobby-events__header img {
    max-height: 2.4rem;
    margin-right: 1rem;
  }
}

.events-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -3rem;
}
.events-list__item {
  width: 100%;
  padding: 0 3rem;
  margin-bottom: 3rem;
}
@media screen and (min-width: 768px) {
  .events-list__item {
    width: 50%;
  }
}

.event-box__header {
  display: flex;
  align-items: center;
  font-size: 2rem;
  font-weight: 300;
  margin-bottom: 1rem;
}
.event-box__header img {
  margin-right: 2.1rem;
}
.event-box__content {
  padding-left: 6rem;
}
.event-box__title {
  margin-bottom: 2rem;
}

@media screen and (min-width: 1260px) {
  .hobby-events {
    padding: 0;
  }
  .hobby-events__row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -3rem;
  }
  .hobby-events__item {
    width: 50%;
    padding: 0 3rem;
    margin-bottom: 5rem;
  }
  .hobby-events__header {
    margin-bottom: 6rem;
  }
}
.hobby-winner {
  padding: 0 2rem;
}
.hobby-winner__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 4rem;
}
.hobby-winner__header img {
  margin-bottom: 3rem;
}
@media screen and (max-width: 767px) {
  .hobby-winner__header img {
    max-width: 4rem;
    margin-bottom: 2rem;
  }
}

.winner-box {
  display: flex;
  align-items: center;
}
.winner-box__image {
  width: 12.8rem;
  height: 12.8rem;
  position: relative;
  border-radius: 0 0 2.5rem 0;
  overflow: hidden;
}
.winner-box__image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}
.winner-box__content {
  padding-left: 1.6rem;
}
.winner-box__header {
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  font-weight: 300;
  margin-bottom: 1rem;
}
.winner-box__header img {
  margin-right: 1rem;
  max-width: 2rem;
}
.winner-box__title {
  font-size: 2rem;
  line-height: 1.25em;
  font-weight: 600;
  margin-bottom: 1rem;
}
.winner-box__attr {
  display: flex;
  font-size: 1.4rem;
  font-weight: 400;
  margin-bottom: 0.6em;
}
.winner-box__attr .name {
  min-width: 7rem;
}

.timeline {
  padding-bottom: 3rem;
  position: relative;
}
.timeline::before {
  content: "";
  position: absolute;
  left: calc(50% - 1px);
  top: 0;
  width: 2px;
  height: 100%;
  background-color: #fff;
  opacity: 0.5;
}
.timeline__point {
  width: 2rem;
  height: 2rem;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  top: calc(50% - 1rem);
  left: calc(50% - 1rem);
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.timeline__point::after {
  content: "";
  width: 1rem;
  height: 1rem;
  background-color: #48A681;
  border-radius: 50%;
}
.timeline__item {
  margin-bottom: 4rem;
  position: relative;
}
.timeline__item:nth-child(2n) .winner-box {
  flex-flow: row-reverse;
}
.timeline__item:nth-child(2n) .winner-box__content {
  padding-left: 0;
  padding-right: 1.6rem;
}

@media screen and (max-width: 767.99px) {
  .winner-box {
    justify-content: flex-end;
    column-gap: 4rem;
  }
  .winner-box__content {
    width: 50%;
  }
}
@media screen and (min-width: 768px) {
  .winner-box {
    justify-content: flex-end;
  }
  .winner-box__content {
    padding-left: 2rem;
  }
  .winner-box::after {
    content: "";
    width: 4rem;
    height: 1px;
    background-color: #fff;
    margin: 0 2rem;
  }
  .timeline {
    padding-bottom: 5rem;
  }
  .timeline__point {
    width: 2rem;
    height: 2rem;
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    top: calc(50% - 1.6rem);
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  .timeline__point::after {
    content: "";
    width: 1rem;
    height: 1rem;
    background-color: #48A681;
    border-radius: 50%;
  }
  .timeline::before {
    content: "";
    position: absolute;
    left: calc(50% - 1px);
    top: 0;
    width: 3px;
    height: 100%;
    background-color: #fff;
    opacity: 0.5;
  }
  .timeline__item {
    width: 50%;
    margin-bottom: -4rem;
  }
  .timeline__item:nth-child(2n+1) .timeline__point {
    right: -1rem;
    left: auto;
  }
  .timeline__item:nth-child(2n) {
    margin-left: auto;
  }
  .timeline__item:nth-child(2n) .timeline__point {
    left: -1rem;
  }
  .timeline__item:nth-child(2n) .winner-box__content {
    padding-left: 0;
    padding-right: 2rem;
  }
}
@media screen and (min-width: 1260px) {
  .hobby-winner {
    padding: 0;
  }
  .winner-box::after {
    width: 10rem;
    margin: 0 3rem;
  }
  .winner-box__image {
    width: 25.8rem;
    height: 25.8rem;
    border-radius: 0 0 5rem 0;
  }
  .winner-box__content {
    padding-left: 6rem;
  }
  .winner-box__header {
    font-size: 2rem;
    margin-bottom: 2rem;
  }
  .winner-box__header img {
    margin-right: 2rem;
    max-width: 4rem;
  }
  .winner-box__title {
    font-size: 3rem;
    margin-bottom: 2rem;
  }
  .winner-box__attr {
    font-size: 1.6rem;
    margin-bottom: 1em;
  }
  .winner-box__attr .name {
    min-width: 12rem;
  }
  .timeline__point {
    width: 3.2rem;
    height: 3.2rem;
  }
  .timeline__point::after {
    width: 1.6rem;
    height: 1.6rem;
  }
  .timeline__item:nth-child(2n+1) .timeline__point {
    right: -1.6rem;
  }
  .timeline__item:nth-child(2n) .timeline__point {
    left: -1.6rem;
  }
  .timeline__item:nth-child(2n) .winner-box__content {
    padding-right: 6rem;
  }
}
.hobby-gallery {
  padding: 0 2rem;
}

.gallery-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -2rem;
}
.gallery-list__item {
  width: 100%;
  padding: 0 2rem;
  margin-bottom: 4rem;
}
@media screen and (min-width: 768px) {
  .gallery-list__item {
    width: 50%;
  }
}
.gallery-list__box {
  display: block;
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
}
.gallery-list__box img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

@media screen and (min-width: 1260px) {
  .hobby-gallery {
    padding: 0;
  }
}
.hobby-adv {
  padding: 0 2rem;
}

@media screen and (min-width: 1260px) {
  .hobby-adv {
    padding: 0;
  }
}
.footer {
  padding: 0 2rem 4rem 2rem;
}
.footer__row {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-size: 1.2rem;
  font-weight: 400;
  margin: 0 -1.5rem 2rem;
}
.footer-menu__item {
  padding: 0 1.5rem;
  margin-bottom: 2rem;
}
.footer-menu a:hover {
  text-decoration: underline;
}

@media screen and (min-width: 576px) {
  .footer-menu {
    font-size: 1.4rem;
    margin: 0 -2rem 2rem;
  }
  .footer-menu__item {
    padding: 0 2rem;
    margin-bottom: 2rem;
  }
}
@media screen and (min-width: 1260px) {
  .footer {
    padding: 0 0 5rem;
  }
  .footer__row {
    flex-direction: row;
    justify-content: space-between;
  }
  .footer-menu {
    margin-bottom: 0;
  }
  .footer-menu__item {
    margin-bottom: 0;
  }
}
.f-contact {
  padding: 0 2rem;
}
.f-contact__row {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 2rem;
  margin-bottom: 1rem;
}
.f-contact__branding {
  max-width: 16rem;
  margin-bottom: 4rem;
}
.f-contact__address {
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.5em;
  margin-bottom: 2rem;
}
.f-contact__btn {
  margin-bottom: 2rem;
}

.btn-contact {
  font-size: 1.8rem;
  font-weight: 400;
  color: #CECECE;
  display: flex;
  align-items: center;
}
.btn-contact--s {
  font-size: 1.6rem;
}
@media screen and (min-width: 576px) {
  .btn-contact--s {
    font-size: 2.6rem;
  }
}
@media screen and (min-width: 576px) {
  .btn-contact {
    font-size: 2.6rem;
  }
}
.btn-contact img {
  max-width: 3rem;
  margin-right: 2rem;
}
@media screen and (min-width: 768px) {
  .btn-contact img {
    max-width: 5rem;
  }
}
.btn-contact a {
  color: #2C3934;
}
.btn-contact a:hover {
  text-decoration: underline;
}

@media screen and (min-width: 1260px) {
  .f-contact {
    padding: 0;
  }
  .f-contact__row {
    flex-direction: row;
    padding-bottom: 5rem;
    margin-bottom: 5rem;
    border-bottom: 1px solid #DBDBDB;
  }
  .f-contact__branding {
    margin-bottom: 0;
  }
  .f-contact__address {
    font-size: 2rem;
    margin-left: 6rem;
    margin-bottom: 0;
  }
  .f-contact__btn {
    margin-left: auto;
    margin-bottom: 0;
  }
}
.author {
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 1px;
}
.author img {
  height: 3.4rem;
  width: auto;
}

@media screen and (min-width: 576px) {
  .author img {
    height: 4rem;
  }
}