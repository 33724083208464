@use "../variables" as variable;

.adv-slider {
    overflow: hidden;
    position: relative;
}

.advs-box {
    color: #fff;

    &__icon {
        height: 8rem;
        margin-bottom: 4rem;

        img {
            max-height: 8rem;
        }
    }

    &__value {
        font-size: 3.2rem;
        font-weight: 500;
        margin-bottom: 1rem;
    }

    &__text {
        font-size: 1.4rem;
        font-weight: 300;
        line-height: 1.5em;
    }
}

.slider-pagination {
    position: relative;
    bottom: 0 !important;
    padding-top: 4rem;
    display: flex;
    justify-content: center;

    .swiper-pagination-bullet {
        width: 26px;
        height: 26px;
        min-width: 26px;
        border: 2px solid transparent;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: transparent;
        opacity: 1;
        cursor: pointer;

        &::after {
            content: "";
            width: 8px;
            height: 8px;
            background-color: #fff;
            border-radius: 50%;
        }

        &-active, &:hover {
            border-color: #fff;
        }
    }
}


@include variable.media(768px, min) {
    .advs-box {
        &__icon {
            height: 10rem;
            margin-bottom: 4rem;
    
            img {
                max-height: 10rem;
            }
        }

        &__value {
            font-size: 4.8rem;
        }

        &__text {
            font-size: 2rem;
        }
    }

    .slider-pagination {
        padding-top: 8rem;
    }
}