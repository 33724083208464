@use "../variables" as variable;

.about-char {
    position: relative;

    svg {
        max-width: 100%;
        height: auto;
    }
    
}

.about-char-box {


    &__content {

    }

    &__icon {
        margin-bottom: 2rem;

        img {
            max-height: 8rem;
            mix-blend-mode: multiply;
        }
    }

    &__info {
        margin-bottom: 1.2rem;
    }

    &__text {
        font-size: 1.2rem;
        line-height: 1.25em;
        font-weight: 300;

        @include variable.media(1260px, min) {
            font-size: 1.6rem;
        }
    }
}


@include variable.media(991.99px, max) {
    .about-char {

        svg {
            display: none;
        }

        &__list {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -1.5rem;
        }

        &__item {
            width: 50%;
            margin-bottom: 3rem;
            padding: 0 1.5rem;
        }
    }

    .about-char-box {
        &__icon {
            height: 8rem;
            display: flex;
            justify-content: center;
            align-items: flex-end;
        }
    }
}


@include variable.media(992px, min) {

    .about-char {
        &__item {
            width: 30%;
            position: absolute;
            display: flex;
    
            &:nth-child(1) {
                bottom: 72%;
                left: 0;
            }
    
            &:nth-child(2) {
                bottom: 72%;
                right: 0;
                justify-content: flex-end;
            }
    
            &:nth-child(3) {
                bottom: 20%;
                left: 6%;
            }
    
            &:nth-child(4) {
                bottom: 20%;
                right: 6%;
                justify-content: flex-end;
            }
        }
    }

    .about-char-box {
        display: flex;
        align-items: flex-end;
        padding-bottom: 1rem;

        &__content {
            max-width: 28rem;
        }

        &__icon {
            margin-right: 4rem;
            max-width: 40%;
            margin-bottom: 0;

            img {
                max-height: 14rem;
                min-width: 6rem;
            }
        }
    }
}