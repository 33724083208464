@use "../../variables" as variable;

.th-about {
    padding: 0 2rem;

    &__header {
        text-align: center;
        max-width: 86rem;
        margin: 0 auto 3rem auto;
    }

    &__content {
        margin-bottom: 3rem;
    }

    &__footer {
        max-width: 86rem;
        margin: 0 auto 4rem auto;
        text-align: center;

        @include variable.media(991.99px, max) {
            display: none;
        }
    }

    &__address {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.8rem;
        line-height: 1.5em;
        font-weight: 400;

        img {
            margin-right: 3rem;
        }
    }
}


@include variable.media(575px, max) {
    .th-about {
        &__address {
            flex-direction: column;
            text-align: center;

            img {
                margin-right: 0;
                margin-bottom: 2rem;
            }
        }
    }
}

@include variable.media(1260px, min) {
    .th-about {
        padding: 0;

        &__address {
            font-size: 2rem;
        }
    }
}