@use "../variables" as variable;

.text {
	font-weight: 300;
	font-size: 1.6rem;
	line-height: 1.75em;
	text-transform: none;
	text-decoration: none;

	&--regular {
		font-weight: 400;
	}

	&--large {
		font-size: 1.8rem;

		@include variable.media(768px, min) {
			font-size: 2.2rem;
		}
	}

	b, strong {
		font-weight: 600;
	}
	

	h1, h2, h3, h4, h5, h6 {
		font-weight: 600;
		line-height: 1.25em;
		margin-bottom: 3rem;
	}

	h1 {
		font-size: 3rem;
	}

	h2 {
		font-size: 2.4rem;
		font-weight: 600;
	}

	h3 {
		font-size: 2.2rem;
		font-weight: 400;
	}

	h4 {
		font-size: 1.5rem;
	}

	h5 {
		font-size: 1.25rem;
	}

	h6 {
		font-size: 1rem;
	}

	p {
		margin-bottom: 20px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	ol {
		list-style: none;
		counter-reset: item;

		& > li {
			padding-left: 3rem;
			margin-top: 1rem;
			position: relative;

			@include variable.media(992px, min) {
				padding-left: 4rem;
				margin-top: 3rem;
			}

			&::before {
				counter-increment: item;
				content: counters(item, ".", decimal-leading-zero) ".";
				position: absolute;
				top: 0;
				left: 0;
				font-size: 1.6rem;
				font-weight: 400;
			}

		}
	}

	ul {
		list-style: none;

		li {
			position: relative;
			padding-left: 3rem;
			margin-top: 2rem;

			@include variable.media(992px, min) {	
				padding-left: 4rem;
				margin-top: 3rem;
			}

			&::before {
				content: "";
				position: absolute;
				top: .5rem;
				left: 0;
				width: 1.2rem;
				height: 1.2rem;
				border-radius: 50%;
				background-color: variable.$secondary;

				@include variable.media(992px, min) {	
					width: 1.6rem;
					height: 1.6rem;
				}
			}
		}
	}

	img {
		height: auto;
	}

	a {
		font-weight: 600;

		&:hover {
			text-decoration: underline;
		}
	}

	hr {
		display: block;
		width: 100%;
		border-bottom: 1px solid #d0dfc0;
		margin-bottom: 40px;
		margin-top: 40px;
	}


	iframe {
		display: block;
		max-width: 100%;
		margin: 0 auto;
		margin-bottom: 40px;
	}

	// a[href^="tel:"], a[href^="mailto:"], a[href^="https://www.google.com/maps/"] {
	// 	display: block;
	// 	font-size: 2rem;
	// 	font-weight: 500;

    //     @include variable.media(576px, min) {	
    //         font-size: 2.6rem;
    //     }

	// 	@include variable.media(1200px, min) {	
	// 		font-size: 3.6rem;
	// 	}

	// 	&:hover {
	// 		text-decoration: underline;
	// 	}
	// }
}


.desc-cols {
	padding: 0 2rem;

	&__item {
		margin-bottom: 4rem;
	}
}


@include variable.media(992px, min) {
	.desc-cols {
		display: flex;
		justify-content: space-between;
		margin: 0 -2rem;
		padding: 0;
	
		@include variable.media(1260px, min) {
			margin: 0 -4rem;
		}
	
		&__item {
			width: 50%;
			padding: 0 4rem;
			max-width: 80rem;
		}
	}
}