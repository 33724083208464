@use "../../variables" as variable;

.tc-form {
    padding: 0 2rem;

    &__title {
        font-size: 2.4rem;
        line-height: 1.25em;
        font-weight: 400;
        margin-bottom: 3rem;
    }
}

@include variable.media(1260px, min) {
    .tc-form {
        padding: 0;

        &__title {
            font-size: 3rem;
            margin-bottom: 5rem;
        }
    }
}