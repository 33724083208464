@use "../variables" as variable;

.attr-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1.6rem;
    font-size: 1.6rem;
    font-weight: 400;

    &__item {
        padding: 0 1.6rem;
        display: flex;
        align-items: center;
    }

    &__icon {
        margin-right: 2rem;

        img {
            max-height: 3.6rem;
            width: auto;
            height: auto;
        }
    }
}