@use "../../variables" as variable;

.tc-map {
    position: relative;
    width: 100%;
    padding-bottom: 100%;
    margin-bottom: 3rem;
  

    @include variable.media(768px, min) {
        padding-bottom: 60%;
    }

    @include variable.media(1260px, min) {
        padding-bottom: 40%;
        margin-bottom: 4rem;
    }
    
    iframe {
        @include variable.cover();
        object-fit: cover;
    }
}