@use "../../variables" as variable;


.hobby-adv {
    padding: 0 2rem;
}

@include variable.media(1260px, min) {
    .hobby-adv {
        padding: 0;
    }
}