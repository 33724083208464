@use "../../variables" as variable;

.social-media {
    display: flex;
    align-items: center;

    &__item {
        margin-bottom: 1rem;
    }

    &__link {
        display: inline-flex;
        padding: 1rem;

        img, svg {
            max-width: 2rem;
            max-height: 2rem;
            transition: all .3s;
        }

        &:hover {
            img, svg {
                transform: scale(1.2);   
            }
        }
    }
}


@include variable.media(1260px, min) {	
    .social-media {
        flex-direction: column;
    }
}