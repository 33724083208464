@use "../../variables" as variable;

.f-contact {
    padding: 0 2rem;

    &__row {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding-bottom: 2rem;
        margin-bottom: 1rem;
    }

    &__branding {
        max-width: 16rem;
        margin-bottom: 4rem;
    }

    &__address {
        font-size: 1.8rem;
        font-weight: 400;
        line-height: 1.5em;
        margin-bottom: 2rem;
    }

    &__btn {
       margin-bottom: 2rem;
    }
}

.btn-contact {
    font-size: 1.8rem;
    font-weight: 400;
    color: #CECECE;
    display: flex;
    align-items: center;

    &--s {
        font-size: 1.6rem;

        @include variable.media(576px, min) {
            font-size: 2.6rem;
        }
    }

    @include variable.media(576px, min) {
        font-size: 2.6rem;
    }
    
    img {
        max-width: 3rem;
        margin-right: 2rem;

        @include variable.media(768px, min) {
            max-width: 5rem;
        }
    }

    a {
        color: variable.$primary;

        &:hover {
            text-decoration: underline;
        }
    }
}


@include variable.media(1260px, min) {
    .f-contact {
        padding: 0;

        &__row {
            flex-direction: row;
            padding-bottom: 5rem;
            margin-bottom: 5rem;
            border-bottom: 1px solid #DBDBDB;
        }

        &__branding {
            margin-bottom: 0;
        }

        &__address {
            font-size: 2rem;
            margin-left: 6rem;
            margin-bottom: 0;
        }

        &__btn {
            margin-left: auto;
            margin-bottom: 0;
        }
    }
}