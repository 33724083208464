@use "../variables" as variable;

.pagination {
    display: flex;
    align-items: center;
    justify-content: center;


    &__btn {
        width: 5rem;
        min-width: 5rem;
        height: 5rem;
        background-color: variable.$light;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;

        &.prev {
            transform: rotate(180deg);
        }

        svg path {
            stroke: variable.$primary;
        }

        &:hover {
            background-color: variable.$secondary;

            svg path {
                stroke: #fff;
            }
        }
    }
}

.pagination-pages {
    display: flex;
    align-items: center;
    font-size: 1.6rem;
    font-weight: 400;

    &__item {
        padding: 0 .3rem;
    }

    &__link {
        width: 4rem;
        min-width: 4rem;
        height: 4rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        padding-top: 3px;

        &.current, &:hover {
            background-color: variable.$secondary;
            color: #fff;
        }
    }
}