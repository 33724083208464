@use "../../variables" as variable;


.hobby-gallery {
    padding: 0 2rem;
}

.gallery-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -2rem;

    &__item {
        width: 100%;
        padding: 0 2rem;
        margin-bottom: 4rem;
     
        @include variable.media(768px, min) {
            width: 50%;
        }
    }

    &__box {
        display: block;
        position: relative;
        width: 100%;
        padding-bottom: 56.25%;

        img {
            @include variable.cover();
            object-fit: cover;
        }
    }
}


@include variable.media(1260px, min) {
    .hobby-gallery {
        padding: 0;
    }
}