@use "../../variables" as variable;

.t-products {
    padding-top: 2rem;
    position: relative;
    padding-bottom: 54rem;

    &__nav {
      
    }

    &__content {

    }

    &__header {
        display: flex;
        align-items: center;
        margin-bottom: 2rem;
        padding: 0 2rem;

        img {
            margin-right: 2rem;
        }
    }

    &__about {
        padding: 2rem;
    }


    &__contact {
        padding: 0 2rem;
        margin-bottom: 4rem;
        font-size: 2.4rem;
        font-weight: 400;
        line-height: 1.5em;

        .btn {
            margin-top: 2rem;
        }
    }

    &__info {
        margin-bottom: 4rem;
    }

    &__list {
        padding: 0 2rem;
    }
}


@include variable.media(1259.99px, max) {	
    .t-products {
        &__banner {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
        }
    
    }
}


@include variable.media(1260px, min) {	
    .t-products {
        padding-top: 8rem;
        padding-bottom: 0;
        display: flex;

        &__nav {
            width: 25%;
            max-width: 400px;
            margin-bottom: 2rem;
        }

        &__content {
            padding-left: 4rem;
            flex-grow: 1;
        }

        &__header {
            display: block;
            min-height: 14rem;
            padding: 0;
            margin-bottom: 0;

            img {
                display: none;
            }
        }

        &__about {
            min-height: 14rem;
            padding: 0 0 4rem 0;
        }

        &__text {
            max-width: 60rem;
            width: 50%;
            padding-right: 4rem;
        }

        &__list {
            padding: 0;
        }

        &__contact {
            position: absolute;
            top: 8rem;
            right: 0;
            width: 35%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0;
            margin-bottom: 0;

            .btn {
                margin-top: 0;
            }

            &::before {
                content: "";
                width: 1px;
                height: 76px;
                background-color: #C5C5C5;
            }
        }
    }
}