@use "../../variables" as variable;

.hobby-wrapper {
    padding-top: 5rem;
    background: rgb(72,166,129);
    background: linear-gradient(135deg, rgba(72,166,129,1) 0%, rgba(212,205,148,1) 100%);
    color: #ffffff;
    padding-bottom: 2rem;
    position: relative;
    overflow: hidden;
}

.hobby-hero {
    position: relative;
    padding: 0 2rem;

    &__dec_1 {
        position: absolute;
        top: 0;
        right: 50%;
    }

    &__dec_2 {
        position: absolute;
        top: 5rem;
        right: 0;
    }

    &__desc {
        margin-bottom: 4rem;
        position: relative;
    }

    &__image {
        display: flex;
        justify-content: center;

        img {
            filter: drop-shadow(0px 50px 35px #55A184);

            @include variable.media(575.99px, max) {
                max-width: 16rem;
            }

            @include variable.media(1259.99px, max) {
                max-width: 26rem;
            }
        }
    }

    &__title {
        margin-bottom: 4rem;
        position: relative;
    }

    &__text {
        font-size: 2.4rem;
        line-height: 1.5em;
        font-weight: 400;
        text-transform: uppercase;
        position: relative;
    }
}


@include variable.media(768px, min) {
    .hobby-hero {
        display: flex;
        align-items: center;

        &__desc {
            width: 50%;
            margin-bottom: 0;
        }
    
        &__image {
            width: 50%;
        }
    }
}


@include variable.media(1260px, min) {

    .hobby-wrapper {
        padding-top: 12rem;
        border-radius: 0 20rem 0 0;

        // &::before {
        //     content: "";
        //     position: absolute;
        //     top: 0;
        //     right: 0;
        //     width: 25%;
        //     max-width: 26rem;
        //     height: 6rem;
        //     background-color: #ffffff;
        // }
    }

    .hobby-hero {
        padding: 0;
    }
}