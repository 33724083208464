@use "../variables" as variable;

.hero {
    position: relative;
    color: #fff;
    margin-bottom: 4rem;

    &__content {
        min-height: 25rem;
        display: flex;
        align-items: center;
        position: relative;
        z-index: 2;
        background-color: variable.$secondary;
    }

    &__row {
        display: flex;
        align-items: flex-start;
        padding: 4rem 2rem;
    }

    &__icon {
        margin-right: 3rem;
    }

    &__title {
        margin-bottom: 3rem;
    }

    &__text {
        font-size: 1.8rem;
        font-weight: 300;
        line-height: 1.5em;
        max-width: 35rem;

        @include variable.media(768px, min) {
            font-size: 2.4rem;
        }

        p {
            margin-bottom: 2rem;

            @include variable.media(768px, min) {
                margin-bottom: 3rem;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__image {
        position: relative;
        width: 100%;
        padding-bottom: 60%;

        img {
            @include variable.cover();
            object-fit: cover;
        }
    }
}


@include variable.media(575px, max) {
    .hero {
        &__icon {
            margin-right: 2rem;
            max-width: 4rem;
        }
    }
}


@include variable.media(1260px, min) {
    .hero {
        //padding-top: 6rem;
        padding-bottom: 6rem;
        margin-bottom: 8rem;

        &::before {
            content: "";
            position: absolute;
            //top: 6rem;
            top: 0;
            left: 0;
            //width: 50%;
            width: 55%;
            height: 49rem;
            background-color: variable.$secondary;
            clip-path: polygon(0 0, 100% 0, 85% 100%, 0% 100%);
        }

        &__content {
            width: 40%;
            min-height: 49rem;
            background: transparent;
        }

        &__image {
            position: absolute;
            top: 6rem;
            right: 0;
            width: 60%;
            height: 49rem;
            clip-path: polygon(15% 0, 100% 0, 100% 100%, 0% 100%);
            padding-bottom: 0;
        }

        &__row {
            padding: 0;
        }
    }
}