@use "../../variables" as variable;

.th-offer {
    &__header {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 2rem;
        margin-bottom: 3rem;
        font-size: 3.2rem;
        font-weight: 600;
        text-transform: uppercase;

        img {
            margin-right: 1.6rem;
            height: 2.5rem;
        }
    }
}

@include variable.media(1260px, min) {
    .th-offer {
        &__header {
            display: none;
        }
    }
}