@use "../../variables" as variable;

.catalog-box {
    padding: 2rem;
    background-color: variable.$light;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    &__text {
        display: none;
        padding: 2rem 0;
        font-size: 1.6rem;
        font-weight: 600;
        text-transform: uppercase;
        line-height: 1.25em;
    }
}

.btn-download {
    display: inline-flex;
    align-items: center;
    font-size: 1.6rem;
    line-height: 1.25em;
    font-weight: 600;
    text-transform: uppercase;
    color: variable.$secondary;

    &:hover {
        text-decoration: underline;
        text-underline-offset: 0.2em;
    }

    img {
        margin-left: 1.4rem;

        @include variable.media(575px, max) {	
            max-width: 2rem;
        }
    }
}


@include variable.media(1260px, min) {	
    .catalog-box {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        padding: 3rem;

        &__text {
            display: block;
            font-size: 1.8rem;
            padding: 0;
        }
    }

    .btn-download {
        font-size: 1.8rem;
    }
}