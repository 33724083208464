@use "../../variables" as variable;

.tc-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 -1.5rem;
    padding: 0 2rem;

    &__item {
        width: 32rem;
        padding: 0 1.5rem;
        margin-bottom: 3rem;
    }
}

.tc-box {
    &__header {
        font-size: 1.6rem;
        font-weight: 400;
        color: #CECECE;
        padding-bottom: 1.5rem;
        margin-bottom: 1.5rem;
        border-bottom: 1px solid variable.$secondary;
    }

    &__text {
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 1.25em;

        p {
            margin-bottom: 1.5rem;
        }

        a[href^="tel:"] {
            display: inline-block;
            margin-bottom: 1rem;
            font-size: 2.4rem;
            font-weight: 500;
            color: variable.$secondary;
            text-underline-offset: 0.1em;
            
            &:hover {
                text-decoration: underline;
            }
     
        }

        a[href^="mailto:"] {
            display: inline-block;
            margin-bottom: 1rem;
            font-size: 1.6rem;
            font-weight: 400;
            text-decoration: underline;
            margin-bottom: 1rem;
            text-underline-offset: 0.1em;

            &:hover {
                color: variable.$secondary;
            }
        }
    }
}


@include variable.media(768px, min) {

    .tc-box {
        &__header {
            font-size: 2rem;
            padding-bottom: 3rem;
            margin-bottom: 3rem;
        }

        &__text {
            font-size: 2.2rem;

            p {
                margin-bottom: 2rem;
            }

            a[href^="tel:"] {
                font-size: 2.6rem;
            }

            a[href^="mailto:"] {
                font-size: 1.8rem;
            }
        }
    }
}



@include variable.media(992px, min) {
    .tc-list { 
        margin: 0 -3rem;

        &__item {
            width: 33.33%;
            padding: 0 3rem;
            margin-bottom: 6rem;
        }
    }
}


@include variable.media(1260px, min) {

    .tc-list { 
        padding: 0;
    }
}