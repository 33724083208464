@use "../../variables" as variable;

.author {
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 1px;

    img {
        height: 3.4rem;
        width: auto;
    }
}

@include variable.media(576px, min) {	
    .author img {
        height: 4rem;
    }
}