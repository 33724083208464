@use "../../variables" as variable;

.header {
	width: 100%;
	position: absolute;
	z-index: 11;
	transition: variable.$transition;
	padding: 0 2rem;

	&__row {
		display: flex;
		align-items: center;
		height: 9.6rem;
	}

	&__branding {

	}

	&__nav {
		display: flex;
		align-items: center;
		pointer-events: all;
	}
}


.brand {
	img {
		max-width: 9rem;
		width: auto;
		height: auto;
	}
}


@include variable.media(1259.99px, max) {	
	.header {
		height: 100vh;
		min-height: 60rem;
		overflow: hidden;
		pointer-events: none;

		&__row {
			pointer-events: all;
		}
	}
}


@include variable.media(1260px, min) {	

	.brand {
		img {
			max-width: 14rem;
		}
	}

	.header {
		padding: 0;

		&__row {
			height: 16rem;
		}
	}	
}