@use "../variables" as variable;

.post-list {
    &__item {
        margin-bottom: 5rem;
    }
}

.post-box {
    &__image {
        width: 100%;
        display: block;
        position: relative;
        padding-bottom: 50%;
        margin-bottom: 3rem;
        overflow: hidden;

        img {
            @include variable.cover();
            object-fit: cover;
            transition: all 1s;
        }

        &:hover {
            img {
                transform: scale(1.05);
            }
        }
    }

    &__date {
        position: absolute;
        top: 1.5rem;
        left: 2rem;

        @include variable.media(576px, min) {
            top: 3rem;
            left: 4rem;
        }
    }

    &__content {
        padding: 0 2rem;
    }

    &__title {
        font-size: 2.4rem;
        line-height: 1.25em;
        margin-bottom: 1rem;
        font-weight: 400;
    }

    &__text {
        margin-bottom: 2rem;
    }
}


.post-date {
    padding: 1rem;
    background-color: variable.$secondary;
    color: #fff;
    font-size: 1.2rem;
    line-height: 1.6rem;
    font-weight: 600;

    .year {
        font-size: 1.4rem;
        line-height: 1.6rem;
    }
}


@include variable.media(768px, min) {
    .post-date {
        padding: 1.4rem 2rem 1rem;
        font-size: 2rem;
        line-height: 3rem;
    
        .year {
            font-size: 2.2rem;
            line-height: 3rem;
        }
    }
    
}

@include variable.media(1260px, min) {
    .post-list {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -4rem;

        &__item {
            width: 50%;
            margin-bottom: 8rem;
            padding: 0 4rem;
        }
    }


    .post-box {

        &__image {
            margin-bottom: 4rem;
        }

        &__content {
            padding: 0;
        }

        &__title {
            font-size: 3rem;
            margin-bottom: 2rem;
        }

        &__text {
            margin-bottom: 3rem;
        }
    }
}