@use "../../variables" as variable;

.sp-about {
    //padding: 0 2rem;
    margin-top: -4rem;

    &__header {
        margin-bottom: 2rem;
    }

    &__title {
        font-size: 2.4rem;
        line-height: 1.25em;
        font-weight: 500;
        margin-bottom: 2rem;
    }

    &__item {
        margin-bottom:4rem;
    }
}

@include variable.media(1259.99px, max) {	
    .single-product .t-products__about {
        display: none;
    }
}

@include variable.media(1260px, min) {	
    .sp-about {
        padding: 0;
        margin-top: 0;

        &__header {
            margin-bottom: 4rem;
        }

        &__title {
            font-size: 3rem;
            margin-bottom: 3rem;
        }

        &__item {
            margin-bottom: 6rem;
        }
    }
}