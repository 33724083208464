@use "../../variables" as variable;

.job-list {
    &__item {

        &:hover {
            background-color: #ECFBF5;
        }

    }
}


.job-box {
    $box: &;
    padding: 0 2rem;

    &__header {
        display: flex;
        align-items: center;
        padding: 3rem 0;
        border-bottom: 1px solid #D2D2D2;
        cursor: pointer;
    }

    &__title {
        font-size: 2.4rem;
        line-height: 1.25em;
        font-weight: 400;
    }

    &__place {
        display: inline-flex;
        align-items: center;
        font-size: 1.8rem;
        font-weight: 400;
        min-width: 30rem;
        margin-left: auto;

        img {
            margin-right: 1rem;
            max-width: 2rem;
        }
    }

    &__action {
        margin-left: auto;
    }

    &__content {
        display: none;
    }

    &__apply {

    }

    &__row {
     
    }

    &__col {
        margin-bottom: 4rem;
    }

    &__contact {
        margin-top: 4rem;
        font-size: 2.4rem;
        font-weight: 400;
        line-height: 1.5em;

        a {
            margin-top: 2rem;
        }
    }

    &__tag {
        font-size: 2.4rem;
        line-height: 1.25em;
        font-weight: 400;
        margin-bottom: 4rem;
        text-transform: uppercase;
    }


    &.active {
        background-color: #ECFBF5;

        #{$box} {
            &__header {
                border-color: transparent;
            }

            &__content {
                display: block;
            }
        }

        .btn-expand {
            transform: rotate(-180deg);
        }
    }
}


@include variable.media(1259.99px, max) {
    .job-box {
        $box: &;
     
        &__header {
            flex-wrap: wrap;
            align-items: flex-start;
        }

        &__title {
            max-width: calc(100% - 5rem);
        }

        &__place {
            order: 3;
            width: 100%;
            padding-top: 1rem;
        }

        &__contact {
            padding-bottom: 4rem;
        }
    }
}


@include variable.media(1260px, min) {
    .job-box {
        $box: &;
        padding: 0;


        &__title {
            font-size: 3.6rem;
        }

        &__place {
            font-size: 2.4rem;

            img {
                max-width: 3rem;
                margin-right: 2rem;
            }
        }

        &__action {
            margin-left: 10rem;
        }

        &__content {
            flex-wrap: wrap;
            margin: 0 -4rem;
            padding: 4rem 0;
            display: none;
        }

        &__apply {
            width: 30%;
            padding: 0 4rem;
        }

        &__row {
            width: 70%;
            display: flex;
            flex-wrap: wrap;
            order: 2;
        }

        &__col {
            width: 50%;
            padding: 0 4rem;
            margin-bottom: 0;
        }

        &__contact {
            margin-top: 8rem;
        }

        &.active {
            #{$box} {
                &__content {
                    display: flex;
                }
            }
        }
    }
}