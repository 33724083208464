@use "../variables" as variable;

.hero-slider {

    &__item {
        height: 40vw;
        max-height: 760px;
        min-height: 460px;
        position: relative;

        .video {
            @include variable.cover();
            object-fit: cover;
        }
    }

    &__img {
        @include variable.cover();
        object-fit: cover;
    }

    &__content {
        @include variable.cover();
        display: flex;
        align-items: center;
        text-align: center;

        @include variable.media(1260px, min) {
            padding: 0 2rem
        }
    }

    &__title {
        margin-bottom: 2rem;
    }

    &__text {
        font-size: 2rem;
        font-weight: 400;
        line-height: 1.4em;
        text-transform: uppercase;
        max-width: 24rem;
        margin: 0 auto 2rem;
    }
}


@include variable.media(768px, min) {
    .hero-slider {
        &__text {
            font-size: 2.4rem;
            margin-bottom: 2rem;
            max-width: 100%;
        }
    }
}