@use "../variables" as variable;

.offer-list {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;

    &__item {
        width: 50%;
        display: flex;

        @include variable.media(992px, min) {	
            width: 25%;
        }
    }
}

.offer-box {
    $box: &;
    display: block;
    width: 100%;
    padding: 3rem 2rem;
    background-color: #F6FBF9;
    position: relative;

    &:hover {
        filter: drop-shadow(0px 15px 25px rgba(72,166,129,.4));
        z-index: 2;
    }

    &__image {
        position: relative;
        width: 100%;
        padding-bottom: 80%;
        margin-bottom: 2rem;

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-width: 100%;
            max-height: 100%;
            mix-blend-mode: darken;
        }
    }

    &__content {
        position: relative;
    }

    &__title {
        font-size: 1.8rem;
        line-height: 1.25em;
        font-weight: 400;
        margin-bottom: .6rem;
    }

    &__text {
        font-size: 1.2rem;
        font-weight: 300;
        line-height: 1.5em;
    }

    &.cover {
        #{$box} {
            &__image {
                position: static;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            &__content {
                color: #fff;
            }
        }
    }
}


@include variable.media(991.99px, max) {
    .offer-list__item:first-child {
        .offer-box {
            background-color: #fff;
        }
    }
}


@include variable.media(1260px, min) {	
    .offer-box {
        $box: &;
        padding: 4rem 3rem;

        &__image {
            margin-bottom: 3rem;
        }

        &__title {
            font-size: 2.2rem;
            margin-bottom: .6rem;
        }

        &__text {
            font-size: 1.6rem;
        }
    }
}