@use "../variables" as variable;

html {
	font-size: 62.5%; //10px
	//font-size: 0.5208333333333333vw;
}

body {
	font-family: variable.$font_primary;
	color: variable.$primary;
	font-size: 1.6rem; //16px
	background-color: #ffffff;
}

main {
	overflow: hidden;
	padding-top: 9.6rem;

	@include variable.media(1260px, min) {
		padding-top: 16rem;
	}
}

.page-template-template-homepage main {
	padding-top: 0;
}


.center {
	text-align: center;
}


.relative {
	position: relative;
}


a,
button {
	cursor: pointer;
}


.title {
	line-height: 1.25em;

	&--1 {
		font-weight: 600;
		font-size: 3.6rem;

		@include variable.media(768px, min) {	
			font-size: 4.8rem;
		}

		@include variable.media(1260px, min) {	
			font-size: 6.4rem;
		}
	}

	&--2 {
		font-weight: 600;
		font-size: 3.6rem;

		@include variable.media(768px, min) {
			font-size: 4.8rem;
		}
	}

	&--3 {
		font-weight: 400;
		font-size: 2.4rem;

		@include variable.media(768px, min) {
			font-size: 3.6rem;
		}
	}

	&--4 {
		font-weight: 400;
		font-size: 2.4rem;
	}

	&--secondary {
		color: variable.$secondary;
	}

	&--upper {
		text-transform: uppercase;
	}

	&--regular {
		font-weight: 400;
	}

	b, strong {
		text-decoration: underline;
		text-decoration-color: variable.$secondary;
		text-underline-offset: 0.2em;
	}

	&--white {
		color: #ffffff;

		b, strong {
		text-decoration-color: #ffffff;
		}
	}
}


.section-margin {
	margin-bottom: 5rem;

	@include variable.media(576px, min) {
		margin-bottom: 8rem;
	}

	@include variable.media(1260px, min) {
		margin-bottom: 10rem;
	}
}

.section-header {
	padding: 2rem 2rem 3rem;

	&__row {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
	}

	&__info {
		font-style: italic;
		color: #EEEEEE;	
		font-weight: 600;
		font-size: 1.8rem;
		line-height: 1.25em;
		text-transform: uppercase;

		@include variable.media(992px, min) {
			font-size: 3em;
		}

		@include variable.media(1560px, min) {	
			font-size: 6.4rem;
		}
	}

	@include variable.media(768px, min) {
		padding: 4rem 2rem 4rem;
	}

	@include variable.media(1260px, min) {
		padding: 8rem 0 6rem;
	}
}