@use "../../variables" as variable;

.th-hero {
    position: relative;

    &__scroll {
        position: absolute;
        left: 50%;
        bottom: -1px;
        transform: translate(-50%, 50%);
        z-index: 2;
    }
}

.btn-scroll {
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 0px;
        height: 0px;
        border-style: solid;
        border-width: 0 50px 50px 50px;
        border-color: transparent transparent #fff transparent;
    }

    img {
        position: relative;
    }
}