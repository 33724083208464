@use "../../variables" as variable;

.th-adv {
    padding: 6rem 2rem 4rem;
    background: rgb(72,166,129);
    background: linear-gradient(135deg, rgba(72,166,129,1) 0%, rgba(212,205,148,1) 100%);

    &__header {
        display: flex;
        align-items: center;
        padding-bottom: 6rem;
        color: #fff;
        position: relative;

        img {
            margin-right: 2rem;
        }

        &::after {
            content: "";
            position: absolute;
            top: calc(100% - 2rem);
            left: 4rem;
            width: 12rem;
            height: 12rem;
            border-radius: 50%;
            background-color: #25815D;

        }
    }

    &__dec {
        position: absolute;
        top: -2rem;
        right: -4rem;
    }
}

@include variable.media(575px, max) {
    .th-adv {
        &__header {
            img {
                margin-right: 1rem;
                max-height: 2.4rem;
            }
        }

        &__dec {
            top: 2rem;
            right: -8rem;
            opacity: .3;
        }
    }
}

@include variable.media(1260px, min) {
    .th-adv {
        padding: 44rem 0 40rem;
        margin-top: -28rem;
        margin-bottom: -28rem;

        &__header {
            margin-right: 6rem;
            padding-bottom: 12rem;
        }
        
        &__dec {
            top: -4rem;
            right: 12rem;
        }
    }
}