@use "../../variables" as variable;

.menu {
    display: flex;
    align-items: center;
  
    &__item {
        position: relative;
        margin-right: 1rem;

        a {
            font-size: 1.6rem;
            font-weight: 300;
            padding: 1.5rem 1.2rem;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            color: variable.$primary;

            &:hover {
                background-color: variable.$secondary;
                color: #fff; 
            }
        }

        &.active a {
            background-color: variable.$secondary;
            color: #fff;
        }
    }
}


@include variable.media(1259.99px, max) {	
    .menu {
        flex-direction: column;
        align-items: center;
    }
}

@include variable.media(1260px, min) {	
    .menu {

    }
}


@include variable.media(1560px, min) {	
    .menu {
        &__item {
            a {
                font-size: 1.6rem;
                font-weight: 300;
                padding: 2.4rem 2.4rem;
            }
        }
    }
}