
#char_about {

    .svg-elem-1 {
        stroke-dashoffset: 1718.281005859375px;
        stroke-dasharray: 1718.281005859375px;
        fill: transparent;
        transition: stroke-dashoffset 1s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0s,
                      fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s;
    }


    .svg-elem-2 {
        stroke-dashoffset: 1718.4376220703125px;
        stroke-dasharray: 1718.4376220703125px;
        fill: transparent;
        transition: stroke-dashoffset 1s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.12s,
                      fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.9s;
    }


    .svg-elem-3 {
        stroke-dashoffset: 1572.7963267948965px;
        stroke-dasharray: 1572.7963267948965px;
        fill: transparent;
        transition: stroke-dashoffset 1s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.24s,
                      fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1s;
    }


    .svg-elem-4 {
        stroke-dashoffset: 1569.6547341413068px;
        stroke-dasharray: 1569.6547341413068px;
        transition: stroke-dashoffset 1s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.36s,
                      fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.1s;
      }


      .svg-elem-5 {
        stroke-dashoffset: 1264.9202467430969px;
        stroke-dasharray: 1264.9202467430969px;
        fill: transparent;
        transition: stroke-dashoffset 1s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.48s,
                      fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2000000000000002s;
      }


      .svg-elem-6 {
        stroke-dashoffset: 506px;
        stroke-dasharray: 506px;
        transition: stroke-dashoffset 1s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.6s,
                      fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.3s;
      }


      .svg-elem-7 {
        stroke-dashoffset: 494px;
        stroke-dasharray: 494px;
        transition: stroke-dashoffset 1s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.72s,
                      fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.4000000000000001s;
      }


      .svg-elem-8 {
        stroke-dashoffset: 494px;
        stroke-dasharray: 494px;
        transition: stroke-dashoffset 1s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.84s,
                      fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.5s;
      }



      .svg-elem-9 {
        stroke-dashoffset: 494px;
        stroke-dasharray: 494px;
        transition: stroke-dashoffset 1s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.96s,
                      fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.6s;
      }


      .svg-elem-10 {
        stroke-dashoffset: 202px;
        stroke-dasharray: 202px;
        fill: transparent;
        transition: stroke-dashoffset 1s cubic-bezier(0.68, -0.55, 0.265, 1.55) 1.08s,
                      fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.7000000000000002s;
      }


      .svg-elem-11 {
        stroke-dashoffset: 202px;
        stroke-dasharray: 202px;
        fill: transparent;
        transition: stroke-dashoffset 1s cubic-bezier(0.68, -0.55, 0.265, 1.55) 1.2s,
                      fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.8s;
      }

      .svg-elem-12 {
        stroke-dashoffset: 190px;
        stroke-dasharray: 190px;
        fill: transparent;
        transition: stroke-dashoffset 1s cubic-bezier(0.68, -0.55, 0.265, 1.55) 1.3199999999999998s,
                      fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.9000000000000001s;
      }

      .svg-elem-13 {
        stroke-dashoffset: 190px;
        stroke-dasharray: 190px;
        fill: transparent;
        transition: stroke-dashoffset 1s cubic-bezier(0.68, -0.55, 0.265, 1.55) 1.44s,
                      fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2s;
      }

      .svg-elem-14 {
        stroke-dashoffset: 52.26548245743669px;
        stroke-dasharray: 52.26548245743669px;
        fill: transparent;
        transition: stroke-dashoffset 1s cubic-bezier(0.68, -0.55, 0.265, 1.55) 1.56s,
                      fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.1s;
      }


      .svg-elem-15 {
        stroke-dashoffset: 52.26548245743669px;
        stroke-dasharray: 52.26548245743669px;
        fill: transparent;
        transition: stroke-dashoffset 1s cubic-bezier(0.68, -0.55, 0.265, 1.55) 1.68s,
                      fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.2s;
      }

      .svg-elem-16 {
        stroke-dashoffset: 52.26548245743669px;
        stroke-dasharray: 52.26548245743669px;
        fill: transparent;
        transition: stroke-dashoffset 1s cubic-bezier(0.68, -0.55, 0.265, 1.55) 1.7999999999999998s,
                      fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.3s;
      }

      .svg-elem-17 {
        stroke-dashoffset: 52.26548245743669px;
        stroke-dasharray: 52.26548245743669px;
        fill: transparent;
        transition: stroke-dashoffset 1s cubic-bezier(0.68, -0.55, 0.265, 1.55) 1.92s,
                      fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.4000000000000004s;
      }

      .svg-elem-18 {
        stroke-dashoffset: 123px;
        stroke-dasharray: 123px;
        transition: stroke-dashoffset 1s cubic-bezier(0.68, -0.55, 0.265, 1.55) 2.04s,
                      fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.5s;
      }


      .svg-elem-19 {
        stroke-dashoffset: 52px;
        stroke-dasharray: 52px;
        transition: stroke-dashoffset 1s cubic-bezier(0.68, -0.55, 0.265, 1.55) 2.16s,
                      fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.6s;
      }


      .svg-elem-20 {
        stroke-dashoffset: 908px;
        stroke-dasharray: 908px;
        fill: transparent;
        transition: stroke-dashoffset 1s cubic-bezier(0.68, -0.55, 0.265, 1.55) 2.28s,
                      fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.7s;
      }

}

.about-char__item {
    opacity: 0;
}


.th-about__content.aos-animate, .th-about__content:not(.aos-init) {

    .about-char__item {
        transition: opacity 0.7s cubic-bezier(0.47, 0, 0.745, 0.715);
        transition-delay: 1.8s;
        opacity: 1;
    }

    #char_about {

        .svg-elem-1 {
            stroke-dashoffset: 0;
            fill: rgb(239, 247, 244);
        }
          
          .svg-elem-2 {
            stroke-dashoffset: 0;
            fill: rgb(239, 247, 244);
          }
          
          .svg-elem-3 {
            stroke-dashoffset: 0;
            fill: rgb(72, 166, 129);
          }
          
          .svg-elem-4 {
            stroke-dashoffset: 0;
          }
          
          .svg-elem-5 {
            stroke-dashoffset: 0;
            fill: rgb(255, 255, 255);
          }
         
          .svg-elem-6 {
            stroke-dashoffset: 0;
          }
           
          .svg-elem-7 {
            stroke-dashoffset: 0;
          }
          
          .svg-elem-8 {
            stroke-dashoffset: 0;
          }
          
          .svg-elem-9 {
            stroke-dashoffset: 0;
          }
          
          .svg-elem-10 {
            stroke-dashoffset: 0;
            fill: rgb(72, 166, 129);
          }
          
          .svg-elem-11 {
            stroke-dashoffset: 0;
            fill: rgb(72, 166, 129);
          }
          
          .svg-elem-12 {
            stroke-dashoffset: 0;
            fill: rgb(72, 166, 129);
          }
        
          .svg-elem-13 {
            stroke-dashoffset: 0;
            fill: rgb(72, 166, 129);
          }
          
          .svg-elem-14 {
            stroke-dashoffset: 0;
            fill: rgb(72, 166, 129);
          } 
          
          .svg-elem-15 {
            stroke-dashoffset: 0;
            fill: rgb(72, 166, 129);
          } 
          
          .svg-elem-16 {
            stroke-dashoffset: 0;
            fill: rgb(72, 166, 129);
          }
          
          .svg-elem-17 {
            stroke-dashoffset: 0;
            fill: rgb(72, 166, 129);
          }
          
          .svg-elem-18 {
            stroke-dashoffset: 0;
          }
          
          .svg-elem-19 {
            stroke-dashoffset: 0;
          } 
          
          .svg-elem-20 {
            stroke-dashoffset: 0;
            fill: url("#pattern");
          }

    }
}
  