@use "../variables" as variable;

.grid {
    width: 100%;
    max-width: 1800px; //1640
    //padding: 0 2rem;
    margin: 0 auto;

    @include variable.media(1260px, min) {	
        padding: 0 4rem;
    }
    
    @include variable.media(1560px, min) {	
        padding: 0 8rem;
    }
}

