@use "../variables" as variable;

::-webkit-input-placeholder { /* Edge */
    color: variable.$primary;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: variable.$primary;
}
::placeholder {
    color: variable.$primary;
}


input[type=text], input[type=email], input[type=tel], input[type=password], textarea {
    font-family: inherit;
    font-size: 1.6rem;
    line-height: 1.5em;
    padding: 1.4rem 2rem;
    border: 2px solid #D5D5D5;
    width: 100%;
    font-weight: 300;
    color: variable.$primary;

    @include variable.media(768px, min) {
        padding: 2rem 2.4rem; 
    }

    &.disabled {
        opacity: 0.5;
        pointer-events: none;
    }

    &.wpcf7-not-valid {
        border-color: variable.$red;
    }

       
    &:focus {
        border-color: variable.$secondary;
    }
}

.wpcf7-form-control-wrap[data-name=place] {
    position: relative;

    &::after {
        content: "";
        position: absolute;
        top: 2.7rem;
        right: 3rem;
        width: 8px;
        height: 20px;
        background-image: url(../../assets/dropdown.svg);
        background-repeat: no-repeat;
        background-position: center;
    }
}

select {
    font-family: inherit;
    font-size: 1.6rem;
    line-height: 1.5em;
    padding: 2rem 2.4rem;
    border: 2px solid #D5D5D5;
    width: 100%;
    font-weight: 300;
    color: variable.$primary;
    position: relative;
    cursor: pointer;

    &:focus {
        border-color: variable.$secondary;
    }
}


input[type=radio] {
    min-width: 2.2rem;
    width: 2.2rem;
    height: 2.2rem;
    border: 1px solid variable.$primary;
    border-radius: 50%;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
 

    &:checked {
        background-color: variable.$secondary;
        border-color: variable.$secondary;
    }
}

input[type=checkbox] {
    min-width: 20px;
    width: 20px;
    height: 20px;
    border: 2px solid #D5D5D5;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
 
    &::after {
        content: "";
        width: 10px;
        height: 10px;
        background-color: variable.$secondary;
        transform: scale(0);
        transition: all 0.3s;
    }

    &:checked {
        &::after {
            transform: scale(1);
        }
    }
}


.wpcf7-list-item label {
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    font-weight: 400;
    cursor: pointer;

    a {
        font-weight: 500;
        
        &:hover {
            text-decoration: underline;
        }
    }

    input {
        margin-right: 2rem;
    }
}


input[type=submit] {
    font-family: inherit;
    font-size: 1.4rem;
    font-weight: 300;

    &:disabled {
        cursor: not-allowed;
    }
}


.wpcf7-form-control-wrap {
    display: block;
    position: relative;
    width: 100%;
}


.wpcf7-not-valid-tip {
    position: absolute;
    top: 100%;
    left: 0;
    font-size: 1.4rem;
    padding: .6rem 2.4rem;
    color: variable.$red;
}

.screen-reader-response {
    display: none;
}

.wpcf7-response-output {
    background: variable.$primary;
    padding: 20px;
    text-align: center;
    color: #fff;
    border-radius: 2rem 0 2rem 0;
    margin-top: 2rem;

    @include variable.media(576px, min) {
        border-radius: 3rem 0 3rem 0;
        margin-top: 3rem;
    }

    &:empty {
        display: none;
    }
}


.form-acceptance {
    margin-bottom: 1.5rem;
}

.form-submit {
    display: flex;
    justify-content: center;
}

.form-field {
    margin-bottom: 20px;
}


.btn-file {
    display: flex;
    align-items: center;
    padding: 2.3rem 2.4rem;
    background-color: variable.$secondary;
    font-size: 1.6rem;
    font-weight: 300;
    color: #fff;
    cursor: pointer;

    .wpcf7-form-control-wrap, input {
        display: none;
    }

    svg, img {
        margin-right: 2rem;
    }
}


@include variable.media(768px, min) {	

    // input[type=text], input[type=email], input[type=tel], input[type=password], textarea {
    //     font-size: 1.8rem;
    //     padding: 2.4rem 0;
    // }

    .form-fields {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -1.6rem;
    }
    
    .form-field {
        width: 33.33%;
        padding: 0 1.6rem;
        margin-bottom: 3.2rem;
    
        &--full {
            width: 100%;
        }
    }
}