@use "../variables" as variable;

.sn {

    &__header {
        padding: 2rem 2rem 0;
    }

    &__action {
        margin-bottom: 2rem;
    }
}

.sp-header {
    display: flex;
    align-items: center;
    margin-bottom: 4rem;

    &__date {
        margin-right: 1.2rem;
    }

    &__action {
       display: none;
    }
}

.sp-more-header {
    display: flex;
    align-items: center;
    margin-bottom: 3rem;
    padding: 0 2rem;

    img {
        margin-right: 1.5rem;

        @include variable.media(767px, max) {
            max-width: 5rem;
        }
    }
}


@include variable.media(1260px, min) {
    .sn {

        &__header {
            padding: 6rem 0 0;
        }

        &__action {
            margin-bottom: 1rem;
            padding-left: 13rem;
        }
    }

    .sp-header {
        margin-bottom: 5rem;

        &__date {
            margin-right: 4rem;
        }

        &__action {
            display: block;
            margin-left: auto;
        }
    }

    .sp-more-header {
        margin-bottom: 6rem;
        padding: 0;

        img {
            margin-right: 3rem;
        }
    }
}