@use "../variables" as variable;

.product-banner {
    height: 54rem;
    position: relative;
    color: #fff;

    &::after {
        content: "";
        @include variable.cover();
        object-fit: cover;
        background-color: variable.$primary;
        opacity: .4;
    }

    &__img {
        @include variable.cover();
        object-fit: cover;
    }

    &__content {
        position: absolute;
        z-index: 2;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 8rem 3rem;
    }

    &__title {
        margin-bottom: 2rem;
        font-size: 4.2rem;
        font-weight: 600;
        line-height: 1.25em;
        text-transform: uppercase;

        b, strong {
            text-decoration: underline;
            text-decoration-color: variable.$secondary;
            text-underline-offset: 0.2em;
        }
    }

    &__text {
        font-size: 2rem;
        font-weight: 400;
        line-height: 1.5em;
    }
}


@include variable.media(1260px, min) {	
    .product-banner {
        height: 60rem;

        &__title {
            font-size: 4.8rem;
        }
    }
}