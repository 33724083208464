@use "../../variables" as variable;

.product-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1.6rem;

    &__item {
        width: 100%;
        padding: 0 1.6rem;
        margin-bottom: 5rem;

        @include variable.media(576px, min) {	
            width: 50%;
        }

        @include variable.media(992px, min) {	
            width: 33.33%;
        }
    }
}


.product-box {
    $box : &;
    display: block;
    width: 100%;

    &__image {
        position: relative;
        padding-bottom: 110%;
        margin-bottom: 2rem;
        overflow: hidden;

        img {
            @include variable.cover();
            object-fit: cover;
            transition: all 1s;
        }
    }

    &__title {
        font-size: 2rem;
        font-weight: 500;
        line-height: 1.25em;
        margin-bottom: 2rem;
    }

    &:hover {
        #{$box} {
            &__image img {
                transform: scale(1.05);
            }

            &__title {
                text-decoration: underline;
            }
        }
    }
}