@use "../variables" as variable;

.info-box {
    position: relative;

    &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 50%;
        background: linear-gradient(0deg, rgba(72,166,129,1) 0%, rgba(72,166,129,0) 100%);

    }

    &__content {
        position: absolute;
        z-index: 2;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: 4rem 2rem;
        color: #fff;
        display: flex;
        align-items: center;

        img {
            max-width: 3rem;
            margin-right: 2rem;
        }
    }

    &__text {
        font-size: 1.8rem;
        line-height: 1.25em;
        font-weight: 400;
    }
}


@include variable.media(768px, min) {
    .info-box {
        &__text {
            font-size: 3.6rem;
        }

        &__content {
            padding: 4rem;

            img {
                max-width: 6rem;
                margin-right: 3rem;
            }
        }
    }
}