@use "../variables" as variable;

.box-about {
    $box: &;
   
    &__image {
        margin-bottom: 3rem;
    }

    &__desc {
        padding: 0 2rem;
    }
}



@include variable.media(992px, min) {	
    .box-about {
        $box: &;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 -4rem;

        &__image {
            padding: 0 4rem;
            margin-bottom: 0;
        }

        &__desc {
            padding: 0 4rem;
            width: 50rem;
            min-width: 50rem;
        }

        &--reverse {
            #{$box} {
                &__image {
                    order: 2;
                }
            }
        }
    }
}