@use "../../variables" as variable;

.footer {
    padding: 0 2rem 4rem 2rem;

    &__row {
        display: flex;
        flex-direction: column;
        align-items: center;

    }
}


.footer-menu {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    font-size: 1.2rem;
    font-weight: 400;
    margin: 0 -1.5rem 2rem;

    &__item {
        padding: 0 1.5rem;
        margin-bottom: 2rem;
    }

    a {
        &:hover {
            text-decoration: underline;
        }
    }
}


@include variable.media(576px, min) {
    .footer-menu {
        font-size: 1.4rem;
        margin: 0 -2rem 2rem;

        &__item {
            padding: 0 2rem;
            margin-bottom: 2rem;
        }
    }
}

@include variable.media(1260px, min) {
    .footer {
        padding: 0 0 5rem;

        &__row {
            flex-direction: row;
            justify-content: space-between;
        }
    }

    .footer-menu {
        margin-bottom: 0;
        
        &__item {
            margin-bottom: 0;
        }
    }
}