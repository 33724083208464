@use "../variables" as variable;

.desc-info {
    padding: 6rem 2rem;
    background-color: variable.$light;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__icon {
        margin-bottom: 4rem;
    }

    &__text {
        font-size: 1.8rem;
        font-weight: 500;
        font-style: italic;
        line-height: 1.5em;
        text-align: center;
    }
}


@include variable.media(768px, min) {
    .desc-info {
        padding: 8rem;
        flex-direction: row;

        &__icon {
            margin-right: 8rem;
            margin-bottom: 0;
        }

        &__text {
            font-size: 2.4rem;
            text-align: left;
        }
    }
}