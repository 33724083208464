@use "../../variables" as variable;

.hobby-winner {
    padding: 0 2rem;

    &__header {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 4rem;

        img {
            margin-bottom: 3rem;

            @include variable.media(767px, max) {
                max-width: 4rem;
                margin-bottom: 2rem;
            }
        }
    }
}

.winner-box {
    display: flex;
    align-items: center;

    &__image {
        width: 12.8rem;
        height: 12.8rem;
        position: relative;
        border-radius: 0 0 2.5rem 0;
        overflow: hidden;

        img {
            @include variable.cover();
            object-fit: cover;
        }
    }

    &__content {
        padding-left: 1.6rem;
    }

    &__header {
        display: flex;
        align-items: center;
        font-size: 1.6rem;
        font-weight: 300;
        margin-bottom: 1rem;

        img {
            margin-right: 1rem;
            max-width: 2rem;
        }
    }

    &__title {
        font-size: 2rem;
        line-height: 1.25em;
        font-weight: 600;
        margin-bottom: 1rem;
    }

    &__attr {
        display: flex;
        font-size: 1.4rem;
        font-weight: 400;
        margin-bottom: .6em;

        .name {
            min-width: 7rem;
        }
    }
}


.timeline {
    padding-bottom: 3rem;
    position: relative;

    &::before {
        content: "";
        position: absolute;
        left: calc(50% - 1px);
        top: 0;
        width: 2px;
        height: 100%;
        background-color: #fff;
        opacity: .5;
    }

    &__point {
        width: 2rem;
        height: 2rem;
        background-color: #fff;
        border-radius: 50%;
        position: absolute;
        top: calc(50% - 1rem);
        left: calc(50% - 1rem);
        display: inline-flex;
        align-items: center;
        justify-content: center;

        &::after {
            content: "";
            width: 1rem;
            height: 1rem;
            background-color: variable.$secondary;
            border-radius: 50%;
        }
    }

    &__item {
        margin-bottom: 4rem;
        position: relative;

        &:nth-child(2n) {
            .winner-box { 
                flex-flow: row-reverse;

                &__content {
                    padding-left: 0;
                    padding-right: 1.6rem;
                }
            }
        }
    }

}


// @include variable.media(420px, max) {
//     .winner-box { 
//         justify-content: space-between;
//     }
// }


@include variable.media(767.99px, max) {
    .winner-box { 
        justify-content: flex-end;
        column-gap: 4rem;

        // &__image {
        //     width: 50%;
        //     max-width: 12.8rem;
        // }

        &__content {
            width: 50%;
        }
    }
}


@include variable.media(768px, min) {

    .winner-box {
        justify-content: flex-end;

        &__content {
            padding-left: 2rem;
        }

        &::after {
            content: "";
            width: 4rem;
            height: 1px;
            background-color: #fff;
            margin: 0 2rem;
        }
    }


    .timeline {
        padding-bottom: 5rem;

        &__point {
            width: 2rem;
            height: 2rem;
            background-color: #fff;
            border-radius: 50%;
            position: absolute;
            top: calc(50% - 1.6rem);
            display: inline-flex;
            align-items: center;
            justify-content: center;
    
            &::after {
                content: "";
                width: 1rem;
                height: 1rem;
                background-color: variable.$secondary;
                border-radius: 50%;
            }
        }

        &::before {
            content: "";
            position: absolute;
            left: calc(50% - 1px);
            top: 0;
            width: 3px;
            height: 100%;
            background-color: #fff;
            opacity: .5;
        }

        &__item {
            width: 50%;
            margin-bottom: -4rem;
        

            &:nth-child(2n+1) {
                .timeline { 
                    &__point {
                        right: -1rem;
                        left: auto;
                    }
                }
            }

            &:nth-child(2n) {
                margin-left: auto;

                .timeline { 
                    &__point {
                        left: -1rem;
                    }
                }

                .winner-box { 
                    &__content {
                        padding-left: 0;
                        padding-right: 2rem;
                    }
                }
            }

        }
        
    }
}


@include variable.media(1260px, min) {

    .hobby-winner {
        padding: 0;
    }

    .winner-box {

        &::after {
            width: 10rem;
            margin: 0 3rem;
        }

        &__image {
            width: 25.8rem;
            height: 25.8rem;
            border-radius: 0 0 5rem 0;
        }

        &__content {
            padding-left: 6rem;
        }

        &__header {
            font-size: 2rem;
            margin-bottom: 2rem;
            
            img {
                margin-right: 2rem;
                max-width: 4rem;
            }
        }

        &__title {
            font-size: 3rem;
            margin-bottom: 2rem;
        }

        &__attr {
            font-size: 1.6rem;
            margin-bottom: 1em;

            .name {
                min-width: 12rem;
            }
        }
    }

    .timeline {

        &__point {
            width: 3.2rem;
            height: 3.2rem;

            &::after {
                width: 1.6rem;
                height: 1.6rem;
            }
        }

        &__item {

            &:nth-child(2n+1) {
                .timeline { 
                    &__point {
                        right: -1.6rem;
                    }
                }
            }

            &:nth-child(2n) {

                .timeline { 
                    &__point {
                        left: -1.6rem;
                    }
                }

                .winner-box { 
                    &__content {
                        padding-right: 6rem;
                    }
                }
            }
        }
    }
}