@use "../../variables" as variable;

.ta-about {
    $box: &;


    &__desc {
       margin-bottom: 4rem;
       padding: 0 2rem;
    }

    &__image {

    }

    &__text {
        margin-bottom: 4rem;
    }
}

@include variable.media(1260px, min) {
    .ta-about {
        $box: &;
    
        &__row {
            display: flex;
            align-items: center;
            margin: 0 -4rem;
        }
    
        &__desc {
            width: 40%;
            padding: 0 4rem;
            margin-bottom: 0;
        }
    
        &__image {
            width: 60%;
            padding: 0 4rem;
        }
    
        &__text {
            margin-bottom: 4rem;
        }
    
        &--reverse {
            #{$box} {
                &__desc {
                    order: 2;
                }
            }
        }
    }
}