@use "../../variables" as variable;


.prop-box {
    padding: 5rem 2rem;
    background: rgb(72,166,129);
    background: linear-gradient(135deg, rgba(72,166,129,1) 0%, rgba(212,205,148,1) 100%);
    color: #ffffff;
    position: relative;

    &__header {
        display: flex;
        justify-content: center;
        margin-bottom: 5rem;
        position: relative;
    }

    &__dec_1 {
        position: absolute;
        top: 2rem;
        right: 1rem;
    }

    &__dec_2 {
        display: none;
    }
}

.prop-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 -2rem;
    position: relative;

    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 45rem;
        padding: 0 2rem;
        margin-bottom: 4rem;
        text-align: center;
    }

    &__icon {
        margin-bottom: 2rem;
    }

    &__text {
        font-size: 1.8rem;
	    line-height: 1.75em;
        font-weight: 400;
    }
}


@include variable.media(992px, min) {
    .prop-list {
        margin: 0 -4rem;
        justify-content: space-between;

        &__item {
            padding: 0 4rem;
        }

        &__icon {
            margin-bottom: 4rem;
        }
    }
}


@include variable.media(1260px, min) {
    .prop-box {
        padding: 9rem 12rem;

        &__dec_2 {
            display: block;
            position: absolute;
            top: 5rem;
            left: 10rem;
        }

        &__dec_1 {
            top: 16rem;
            right: 10rem;
        }
    }
}