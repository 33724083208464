*,
::after,
::before {
	margin: 0;
	padding: 0;
	border: 0;
	vertical-align: baseline;
	box-sizing: border-box;
}

img,
article,
main,
aside,
details,
figcaption,
figure,
footer,
header,
nav {
	display: block
}

address {
	font-style: inherit;
}

img {
	max-width: 100%;
}

main {
	//overflow: hidden;
}

ol,
ul {
	list-style: none
}

li:empty,
p:empty {
	display: none;
}

textarea,
select,
input,
button {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	color: inherit;
	background: transparent;
}

strong {
	font-weight: bold;
}

a {
	text-decoration: none;
	color: inherit;
}

:focus,
:active {
	outline: none;
	//sorry
}