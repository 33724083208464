@use "../../variables" as variable;

.nav {
	width: 100%;

	&__socials {
		
	}

}

.hamburger {
	$size: 40px;
	width: $size;
	height: $size;
	z-index: 100;
	position: relative;
	cursor: pointer;
	margin-left: auto;
	overflow: hidden;

	// span {
	// 	font-size: 1.4rem;
	// 	font-weight: 600;
	// 	margin-left: 1rem;
	// }

	svg {
		position: absolute;
		right: -15%;
		height: 100%;
		transform-origin: top right;
	}

	path {
		fill: none;
		stroke: variable.$primary;
		stroke-width: 3;
		stroke-linecap: round;
		stroke-linejoin: round;
		--length: 24;
		--offset: -38;
		stroke-dasharray: var(--length) var(--total-length);
		stroke-dashoffset: var(--offset);
		transition: variable.$transition;

		&:nth-child(1),
		&:nth-child(3) {
			--total-length: 126.64183044433594;
		}

		&:nth-child(2) {
			--total-length: 70;
		}
	}
}



@include variable.media(1259.99px, max) {	
    .nav {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
		min-height: 100vh;
        background-color: #fff;
        opacity: 0;
        transform: translateX(100%);
        transition: variable.$transition;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		justify-content: space-between;
		padding-top: 3rem;
		padding-bottom: 4rem;
		border-bottom: 2px solid variable.$secondary;

		&__langs {
			order: 1;
			margin-bottom: 2rem;
		}

		.menu {
			order: 2;
			margin-bottom: 1rem;
		}

		&__socials {
			order: 3;
		}
    }

	.nav-toggled {

		// .header {
		// 	border-bottom: 2px solid variable.$secondary;
		// 	padding-bottom: 2rem;
		// }
		
		.nav {
			transform: translateX(0);
			opacity: 1;
			//filter: drop-shadow(0px 15px 25px rgba(72,166,129,.4));
		}
	
		.hamburger {
			path {
				stroke: variable.$primary;
	
				&:nth-child(1),
				&:nth-child(3) {
					--length: 22.627416998;
					--offset: -94.1149185097;
				}
	
				&:nth-child(2) {
					--length: 0;
					--offset: -50;
				}
			}
		}
	}
}


@include variable.media(1260px, min) {	

	.nav {
		display: flex;
		justify-content: flex-end;
		padding-right: 4rem;

		&__langs {
			margin-left: 2rem;
		}

		&__socials {
			position: absolute;
			top: 6rem;
			right: 2rem;

			@include variable.media(1860px, min) {	
				right: 3rem;
			}
		}
	}

	.hamburger {
		display: none;
	}
}


@include variable.media(1560px, min) {	
	.nav {
		padding-right: 0;

		&__langs {
			margin-left: 6rem;
		}
	}
}