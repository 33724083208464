@use "../variables" as variable;

.btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 2.4rem;
    min-height: 5.2rem;
    background-color: variable.$secondary;
    color: #fff;
    font-size: 1.6rem;
    font-weight: 300;
    min-width: 15rem;
    cursor: pointer;
    transition: all .3s;

    span {
        margin-right: 1.4rem;
    }

    .wpcf7-spinner {
        display: none;
    }

    img, svg {
        transition: all .3s;
    }

    &:hover {
        //filter: drop-shadow(0px 15px 25px #96E2C4);
        filter: drop-shadow(0px 15px 25px rgba(72,166,129,.4));
    
        img, svg {
            transform: rotate(45deg);
        }
    }
}


.btn-link {
    display: inline-flex;
    align-items: baseline;
    font-size: 1.6rem;
    font-weight: 500;
    text-transform: uppercase;

    &:hover {
        text-decoration: underline;
        color: variable.$secondary;
    }

    img {
        margin-right: 1.6rem;
    }
}


.btn-apply {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    //padding: 3rem 6rem 3rem 4rem;
    padding: 2rem 4rem 2rem 3rem;
    background-color: variable.$secondary;
    color: #fff;
    font-size: 2.8rem;
    font-weight: 600;
    text-transform: uppercase;

    img {
        max-width: 5rem;
        max-height: 5rem;
        width: auto;
        height: auto;
        margin-right: 2rem;
    }
    
    &:hover {
        //filter: drop-shadow(0px 15px 25px #96E2C4);
        filter: drop-shadow(0px 15px 25px rgba(72,166,129,.4));
    }

    @include variable.media(575px, max) {
        width: 100%;
    }
}


.btn-expand {
    width: 4rem;
    min-width: 4rem;
    height: 4rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: variable.$secondary;
    border-radius: 50%;
    transition: all .3s;

    @include variable.media(1260px, min) {
        width: 6rem;
        min-width: 6rem;
        height: 6rem;
    }
}

@include variable.media(576px, min) {

}