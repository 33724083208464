@use "../../variables" as variable;

.ta-adv {
    padding: 6rem 2rem 4rem;
    background: rgb(72,166,129);
    background: linear-gradient(135deg, rgba(72,166,129,1) 0%, rgba(212,205,148,1) 100%);
}


@include variable.media(1260px, min) {
    .ta-adv {
        padding: 12rem 0 8rem;
    }
}