@use "../../variables" as variable;

.lang-switcher {
    display: flex;
    align-items: center;
   

    a {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        font-size: 1.6rem;
        font-weight: 300;
        position: relative;
        padding-top: 3px;

        &::after {
            content: "";
            position: absolute;
            top: 36px;
            left: 12px;
            width: 0px;
            height: 0px;
            border-style: solid;
            border-width: 10px 9px 0 9px;
            border-color: transparent transparent transparent transparent;
        }

        &:hover {
            background-color: variable.$secondary;
            color: #fff;
        }
    }

    .lang-item {
        margin-left: 1rem;

        &.current-lang a {
            background-color: variable.$secondary;
            color: #fff;

            &::after {
                border-color: variable.$secondary transparent transparent transparent;
            }
        }
    }
}