@use "../../variables" as variable;

.hobby-events {
    padding: 0 2rem;

    &__item {
        margin-bottom: 5rem;
    }

    &__header {
        display: flex;
        align-items: center;
        margin-bottom: 4rem;

        img {
            margin-right: 4rem;

            @include variable.media(575px, max) {
                max-height: 2.4rem;
                margin-right: 1rem;
            }
        }
    }
}


.events-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -3rem;

    &__item {
        width: 100%;
        padding: 0 3rem;
        margin-bottom: 3rem;

        @include variable.media(768px, min) {
            width: 50%;
        }
    }
}


.event-box {
    &__header {
        display: flex;
        align-items: center;
        font-size: 2rem;
        font-weight: 300;
        margin-bottom: 1rem;

        img {
            margin-right: 2.1rem;
        }
    }
    
    &__content {
        padding-left: 6rem;
    }

    &__title {
        margin-bottom: 2rem;
    }
}



@include variable.media(1260px, min) {
    .hobby-events {
        padding: 0;

        &__row {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -3rem;
        }

        &__item {
            width: 50%;
            padding: 0 3rem;
            margin-bottom: 5rem;
        }

        &__header {
            margin-bottom: 6rem;
        }
    }
}